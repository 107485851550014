// src/components/ConditionalTeamPage/ConditionalTeamPage.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import TeamPage from '../TeamPage/TeamPage';
import AdminTeamManagement from '../AdminTeamPage/AdminTeamPage';
import { useUser } from '../../context/UserContext';

const ConditionalTeamPage = () => {
  const { user, loading } = useUser();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/error" />; // Redirect to an error page if user data fails to load
  }


  return user.is_admin === true ? <AdminTeamManagement /> : <TeamPage />;
};

export default ConditionalTeamPage;
