import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import SignupComponent from './SignUp';
import CompanyInfoPage from './CompanyInfo';
import { signupUser, signupUserWithInviteToken } from '../../services/signup';
import { loginWithGoogle, loginWithMicrosoft } from '../../services/auth';
import { companyCreate } from '../../services/company';
import { useAuth } from '../../hooks/useAuth';

const SignupFlow = () => {
  const [step, setStep] = useState('signup');
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { saveTokens, authToken, isAuthenticated } = useAuth();

  // Token that we want to preserve across re-renders and redirects
  const [savedToken, setSavedToken] = useState('');

  // Get URL parameters
  const queryParams = new URLSearchParams(location.search);
  const inviteToken = queryParams.get('token');
  const accessToken = queryParams.get('access_token');
  const refreshToken = queryParams.get('refresh_token');
  const userDataParam = queryParams.get('user');
  const errorParam = queryParams.get('error');

  // Clear invitation token and state
  const clearInvitationToken = () => {
    localStorage.removeItem('inviteToken');
    setSavedToken('');
  };

  // Check if user is already authenticated
  useEffect(() => {
    if (isAuthenticated) {
      clearInvitationToken(); // Clear any dangling tokens
      navigate('/home', { replace: true });
    }
  }, [isAuthenticated, navigate]);

  // Handle invitation token from URL or localStorage
  useEffect(() => {
    if (inviteToken) {
      localStorage.setItem('inviteToken', inviteToken);
      setSavedToken(inviteToken);
    } else {
      const storedToken = localStorage.getItem('inviteToken');
      if (storedToken) {
        setSavedToken(storedToken);
      }
    }

    // Cleanup function to handle component unmount or route change
    return () => {
      // Only clear token if user is authenticated or there was an error
      if (isAuthenticated || errorParam) {
        clearInvitationToken();
      }
    };
  }, [inviteToken, errorParam, isAuthenticated]);

  // Handle OAuth redirect
  useEffect(() => {
    const handleOAuthRedirect = async () => {
      if (!accessToken || !refreshToken || !userDataParam) return;

      try {
        const user = JSON.parse(decodeURIComponent(userDataParam));
        
        setUserData(user);
        saveTokens(accessToken, refreshToken);

        // Clean up URL parameters
        window.history.pushState({}, '', window.location.pathname);

        if (user.company_id) {
          clearInvitationToken(); // Token was successfully used
          navigate('/home', { replace: true });
        } else {
          setStep('companyInfo');
        }
      } catch (error) {
        console.error('Error processing OAuth response:', error);
        setError('Failed to process authentication');
        navigate('/signup', { replace: true });
      }
    };

    // Handle OAuth error
    if (errorParam) {
      console.error('OAuth error:', errorParam);
      setError(decodeURIComponent(errorParam));
      
      // Don't clear token on authentication error - might want to retry
      navigate('/signup', { replace: true });
      return;
    }

    handleOAuthRedirect();
  }, [accessToken, refreshToken, userDataParam, errorParam, navigate, saveTokens]);

  const handleSignupSubmit = async (data) => {
    setLoading(true);
    setError('');
    
    try {
      const signupData = {
        email: data.email,
        password: data.password,
        username: data.fullName.trim()
      };

      let response;
      const currentInviteToken = savedToken || localStorage.getItem('inviteToken');
      
      if (currentInviteToken) {
        response = await signupUserWithInviteToken(signupData, currentInviteToken);
        setUserData(response.user);
        saveTokens(response.access_token, response.refresh_token);
        clearInvitationToken(); // Token was successfully used
        navigate('/home', { replace: true });
      } else {
        response = await signupUser(signupData);
        setUserData(response.user);
        saveTokens(response.access_token, response.refresh_token);
        setStep('companyInfo');
      }
    } catch (error) {
      console.error('Error during signup:', error);
      setError(error.message || 'Failed to sign up');
    } finally {
      setLoading(false);
    }
  };

  const handleCompanyInfoSubmit = async (companyData) => {
    setLoading(true);
    setError('');
    
    try {
      const response = await companyCreate(companyData, userData.id, authToken);
      saveTokens(response.access_token, response.refresh_token);
      clearInvitationToken(); // Clear any dangling tokens
      navigate('/home', { replace: true });
    } catch (error) {
      console.error('Error during company creation:', error);
      setError(error.message || 'Failed to create company');
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSignup = async () => {
    try {
      const currentInviteToken = savedToken || localStorage.getItem('inviteToken');
      await loginWithGoogle(currentInviteToken);
    } catch (error) {
      console.error('Error initiating Google signup:', error);
      setError('Failed to start Google sign up');
    }
  };

  const handleMicrosoftSignup = async () => {
    try {
      const currentInviteToken = savedToken || localStorage.getItem('inviteToken');
      await loginWithMicrosoft(currentInviteToken);
    } catch (error) {
      console.error('Error initiating Microsoft signup:', error);
      setError('Failed to start Microsoft sign up');
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="w-full">
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
          <span className="block sm:inline">{error}</span>
        </div>
      )}
      
      {step === 'signup' && (
        <SignupComponent 
          onSubmit={handleSignupSubmit}
          onGoogleSignup={handleGoogleSignup}
          onMicrosoftSignup={handleMicrosoftSignup}
          inviteToken={savedToken}
        />
      )}
      
      {!savedToken && step === 'companyInfo' && (
        <CompanyInfoPage 
          onSubmit={handleCompanyInfoSubmit}
        />
      )}
    </div>
  );
};

export default SignupFlow;