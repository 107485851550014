import React, { useState } from 'react';
import AppRoutes from '../../routes/AppRoute';
import TopBar from '../TopBar/TopBar';
import ContentTypeSelector from '../ContentTypeSelector/ContentTypeSelector';
import './MainContent.css';

const MainContent = () => {
  const [contentType, setContentType] = useState('meetings');

  return (
    <div className="main-content">
      <TopBar />
      <ContentTypeSelector 
        selectedType={contentType}
        onTypeChange={setContentType}
      />
      <div className="content-wrapper">
        <AppRoutes contentType={contentType} />
      </div>
    </div>
  );
};

export default MainContent;
