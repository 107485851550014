// src/services/signup.js

const baseUrl = process.env.REACT_APP_CORE_BACKEND_BASE_URL;

export const signupUser = async (formData) => {
    const { email, password, username, is_active = true, is_admin = true } = formData;

    try {
        const response = await fetch(`${baseUrl}/auth/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email,
                password,
                username,
                is_active,
                is_admin,
            }),
        });

        if (!response.ok) {
            throw new Error('Failed to create account');
        }

        return await response.json();
    } catch (error) {
        console.error('Error during signup:', error);
        throw error;
    }
};

export const signupUserWithInviteToken = async (formData, token) => {
  try {
    const response = await fetch(
      `${baseUrl}/auth/register-with-invitation?token=${encodeURIComponent(token)}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: formData.email,
          password: formData.password,
          first_name: formData.first_name,
          last_name: formData.last_name
        }),
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.detail || 'Failed to create account with invite token');
    }

    return await response.json();
  } catch (error) {
    console.error('Error during signup with invite token:', error);
    throw error;
  }
};