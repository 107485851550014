import React, { useState } from "react";
import "./LandingPage.css";
import '../../fonts.css';
import ChatWidget from './ChatWidget/ChatWidget';
import AnimatedBackground from './AnimatedBackground';
import DataSourcesAnimation from './DataSourcesAnimation/DataSourcesAnimation'
import { Play, Video, FileText, Search, Users } from 'lucide-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBrain } from '@fortawesome/free-solid-svg-icons';
import BrainAnimation from './BrainAnimation';

const LandingPage = () => {
  // Add state for play button visibility
  const [showPlayButton, setShowPlayButton] = useState(true);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Add function to handle play button click
  const handlePlayClick = () => {
    const video = document.querySelector('.video-container video');
    if (video) {
      video.play();
      setShowPlayButton(false);
    }
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className="landing-page">
      <AnimatedBackground />
      <header className="header">
        <div className="logo-landing">Inveris</div>
        
        {/* Mobile menu button */}
        <button className="mobile-menu-button" onClick={toggleMobileMenu}>
          <div className={`hamburger ${isMobileMenuOpen ? 'open' : ''}`}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>

        <nav className={`nav-menu ${isMobileMenuOpen ? 'mobile-open' : ''}`}>
          <a href="/product" className="nav-item dropdown">Product</a>
          <a href="/use-case" className="nav-item dropdown">Use Case</a>
          <a href="/wall-of-love" className="nav-item">Wall of Love</a>
          <a href="/resources" className="nav-item dropdown">Resources</a>
          <div className="nav-item pricing-wrapper">
            <a href="/pricing" className="nav-item pricing-strike">Pricing</a>
            <span className="free-label">Free for now!</span>
          </div>
          
          {/* Move auth buttons inside nav for mobile */}
          <div className="auth-buttons mobile">
            <a href="/login" className="login-button">Login</a>
            <a href="/signup" className="free-trial-button">Free Trial</a>
          </div>
        </nav>
        
        {/* Add separate overlay div */}
        {isMobileMenuOpen && <div className="mobile-menu-overlay" />}
        
        {/* Keep desktop auth buttons separate */}
        <div className="auth-buttons desktop">
          <a href="/login" className="login-button">Login</a>
          <a href="/signup" className="free-trial-button">Free Trial</a>
        </div>
      </header>
      
      {/* First Section */}
      <div className="landing-container"> 
        <div className="content">
          <div className="text-content">
            <h1>Your Company's <span className="living-text">Living</span> Memory</h1>
            <p>
              Tired of sifting through endless meeting notes and documents? <br />
              <span className="highlight">Inveris</span> has the solution.
            </p>
            <p>
              Build your company's <strong>knowledge base</strong> effortlessly. Inveris converts meetings, documents, and more into a unified, searchable repository.
            </p>
          </div>
          <div className="widget-container">
            <ChatWidget />
          </div>
        </div>
      </div>

      {/* Meet Your Most Valuable Employee Section */}
      <section className="knowledge-base-section">
        <div className="section-content">
          <div className="kb-content">
            <div className="kb-text">
              <h2 className="section-title">Meet Your Most Valuable Employee</h2>
              <div className="text-wrapper">
                <p className="primary-text">
                  Over time, <span className="highlight-word"> Inveris</span> transforms into your company's  <span className="highlight-word"> living memory</span>—absorbing every meeting, document, and conversation.
                </p>
                <div className="feature-points">
                  <div className="feature-point">
                    <span className="feature-icon">🧠</span>
                    <p>Never forgets a detail</p>
                  </div>
                  <div className="feature-point">
                    <span className="feature-icon">⚡</span>
                    <p>Instant knowledge access</p>
                  </div>
                  <div className="feature-point">
                    <span className="feature-icon">🔄</span>
                    <p>Continuous learning</p>
                  </div>
                </div>
                <p className="secondary-text">
                  With Inveris, every piece of knowledge becomes instantly accessible, 
                  empowering your team to make smarter decisions, faster.
                </p>
              </div>
            </div>
            
            <div className="brain-visualization">
              <div className="ai-container">
                <BrainAnimation />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* How It Works Section */}
      <section className="how-it-works-section">
        <div className="section-content">
          <h2 className="section-title">How It Works</h2>
          <div className="steps-container">
            <div className="step">
              <div className="step-icon-wrapper">
                <Video className="step-icon" />
              </div>
              <h3>Auto-Join Meetings</h3>
              <p>
                Inveris automatically joins your meetings, capturing every conversation to fuel your company's living knowledge base.
              </p>
            </div>
            
            <div className="step">
              <div className="step-icon-wrapper">
                <FileText className="step-icon" />
              </div>
              <h3>Transform Conversations</h3>
              <p>
                Our AI converts discussions into detailed insights, turning meetings into valuable knowledge.
              </p>
            </div>
            
            <div className="step">
              <div className="step-icon-wrapper">
                <Users className="step-icon" />
              </div>
              <h3>Integrate All Data</h3>
              <p>
                Upload files—mp3, mp4, PDFs, and more—and let Inveris consolidate them into one unified, searchable repository.
              </p>
            </div>
            
            <div className="step">
              <div className="step-icon-wrapper">
                <Search className="step-icon" />
              </div>
              <h3>Instant Query</h3>
              <p>
                Ask any question and get immediate answers from your company's collective intelligence—like having ChatGPT on call.
              </p>
            </div>
          </div>

          <div className="demo-section">
            <h3>See It In Action</h3>
            <div className="video-container">
              <video poster="/img/inveris_home.png" controls>
                <source src="https://media-hosting.imagekit.io//997221075b4d4791/inveris-test.mp4?Expires=1833993892&Key-Pair-Id=K2ZIVPTIP2VGHC&Signature=29ulETSMlOsSR8Sltl7HUsLZ6KGX~sYrbupbSbMKUd3OlZ9hqYv1vSM8EWVWSU-RH-cbZNvOWJDGO9eqe-ke-S-33IslMLx8WoPuCALUJ3ojzNO8qGFiRe-vsbK7c5FSozEbHeePx~uG2fOHTu1HZdcbK7-~sPEhc8Y8IQtVf1MpxNBWtBZiFCcKVwZjEpx2356vTkojL8l168RCH6XmU5Hcncn-Dqnji351-rgXI5k2l7VYaBEBvCMfxGQpJFCfAQtbF~VaSmhJRh6s28jWGeGLIp8BIYiHf--iq-YheJFtEyQQWHLaiNtANJ-TNVmuDra5NfAjmcnt9HRSZ08KLQ__" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              {showPlayButton && (
                <button className="play-button" onClick={handlePlayClick}>
                  <Play size={24} />
                </button>
              )}
            </div>
            <p className="demo-note">Demo video coming soon.</p>
          </div>
        </div>
      </section>

      {/* Data Sources Section */}
      <section className="data-sources-section">
        <DataSourcesAnimation />
      </section>

      <footer className="footer">
        <div className="footer-content">
          <div className="footer-left">
            <div className="footer-logo">Inveris</div>
            <p className="footer-tagline">Your Company's Living Memory</p>
          </div>
          <div className="footer-links">
            <div className="footer-column">
              <h4>Product</h4>
              <a href="/features">Features</a>
              <a href="/pricing">Pricing</a>
              <a href="/integrations">Integrations</a>
            </div>
            <div className="footer-column">
              <h4>Company</h4>
              <a href="/about">About</a>
              <a href="/contact">Contact</a>
              <a href="/blog">Blog</a>
            </div>
            <div className="footer-column">
              <h4>Resources</h4>
              <a href="/docs">Documentation</a>
              <a href="/support">Support</a>
              <a href="/privacy">Privacy</a>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; 2025 Inveris. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;