import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import { Chrome, Mail } from 'lucide-react';
import { useAuth } from '../../hooks/useAuth';
import { loginUser, loginWithGoogle, loginWithMicrosoft } from '../../services/auth';
import './SignIn.css';
import AnimatedBackground from '../LandingPage/AnimatedBackground';

const SignInComponent = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { saveTokens, authToken } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const accessToken = queryParams.get('access_token');
  const refreshToken = queryParams.get('refresh_token');
  const userDataParam = queryParams.get('user');
  const error = queryParams.get('error');

  useEffect(() => {
    // Handle direct auth token
    if (authToken) {
      navigate('/home');
      return;
    }

    // Handle OAuth redirect
    const handleOAuthRedirect = async () => {
      if (!accessToken || !refreshToken || !userDataParam) return;

      try {
        const user = JSON.parse(userDataParam);

        await saveTokens(accessToken, refreshToken);

        // Clear URL parameters
        const newUrl = window.location.pathname;
        window.history.pushState({}, '', newUrl);

        // Navigate to home or company creation
        if (user.company_id) {
          navigate('/home', { replace: true });
        } else {
          navigate('/signup', { replace: true });
        }
      } catch (error) {
        console.error('Error processing OAuth response:', error);
        alert('Failed to sign in');
      }
    };

    // Handle OAuth errors
    if (error) {
      console.error('OAuth error:', error);
      alert(`Authentication failed: ${error}`);
      return;
    }

    handleOAuthRedirect();
  }, [authToken, accessToken, refreshToken, userDataParam, error, navigate, saveTokens]);

  const onSubmit = async (data) => {
    try {
      const response = await loginUser(data.email, data.password);
      await saveTokens(response.access_token, response.refresh_token);
      navigate('/home');
    } catch (error) {
      console.error('Error during login:', error);
      alert('Failed to login');
    }
  };

  const handleGoogleLogin = async () => {
    try {
      await loginWithGoogle();
    } catch (error) {
      console.error('Error initiating Google login:', error);
      alert('Failed to start Google sign in');
    }
  };

  const handleMicrosoftLogin = async () => {
    try {
      await loginWithMicrosoft();
    } catch (error) {
      console.error('Error initiating Microsoft login:', error);
      alert('Failed to start Microsoft sign in');
    }
  };

  if (authToken) return null;

  return (
    <div className="signin">
      <AnimatedBackground />
      <header className="header">
        <a className="logo-landing" href="/">Inveris</a>
      </header>
      <main className="signin__main">
        <div className="signin__container">
          <h1 className="signin__title">Welcome to Inveris</h1>
          <p className="signin__subtitle">Connect with your work email and calendar</p>
          
          <div className="signin__oauth-buttons">
            <button 
              onClick={handleGoogleLogin}
              className="signin__oauth-button signin__oauth-button--google"
            >
              <Chrome size={20} />
              Continue with Google
            </button>
            <button 
              onClick={handleMicrosoftLogin}
              className="signin__oauth-button signin__oauth-button--microsoft"
            >
              <Mail size={20} />
              Continue with Microsoft
            </button>
          </div>

          <div className="signin__divider">
            <span className="signin__divider-text">OR</span>
          </div>

          <form className="signin__form" onSubmit={handleSubmit(onSubmit)}>
            <input
              type="email"
              placeholder="Work email"
              className={`signin__input ${errors.email ? 'error' : ''}`}
              {...register("email", { required: "Email is required" })}
            />
            {errors.email && <p className="error-message">{errors.email.message}</p>}

            <input
              type="password"
              placeholder="Password"
              className={`signin__input ${errors.password ? 'error' : ''}`}
              {...register("password", { required: "Password is required" })}
            />
            {errors.password && <p className="error-message">{errors.password.message}</p>}

            <button type="submit" className="signin__submit">Sign in</button>
          </form>

          <div className="signin__account-prompt">
            Don't have an account? <a onClick={() => navigate('/signup')} className="signin__signup-link">Sign up</a>
          </div>

          <div className="signin__links">
            <a href="/terms" className="signin__link">Terms of Service</a>
            <span className="signin__link-separator">•</span>
            <a href="/privacy" className="signin__link">Privacy Policy</a>
          </div>

          <div className="signin__trial-note">
            14-day free trial. No credit card required.
          </div>
        </div>
      </main>
    </div>
  );
};

export default SignInComponent;