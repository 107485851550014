import React, { useState, useEffect, useRef } from 'react';
import './Upload.css';
import { useAuth } from '../../hooks/useAuth';
import { useUser } from '../../context/UserContext';
import TeamSelector from '../SharedComponents/TeamSelector/TeamSelector';

const baseUrl = process.env.REACT_APP_DATA_BACKEND_BASE_URL;

const UploadProgressNotification = ({ progress, fileName }) => {
    const [showSuccess, setShowSuccess] = useState(false);
  
    useEffect(() => {
        if (progress === 100) {
            setShowSuccess(true);
            const timer = setTimeout(() => {
                setShowSuccess(false);
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [progress]);
  
    return (
        <div className={`upload-notification ${progress > 0 ? 'upload-notification--visible' : ''} 
            ${showSuccess ? 'upload-notification--success' : ''}`}>
            <div className="upload-notification__content">
                <div className="upload-notification__header">
                    <div className="upload-notification__title">
                        {showSuccess ? (
                            <>
                                <span className="upload-notification__success-icon">✓</span>
                                Upload Complete
                            </>
                        ) : (
                            `Uploading ${fileName}`
                        )}
                    </div>
                    <div className="upload-notification__progress-container">
                        <div 
                            className="upload-notification__progress-bar" 
                            style={{ width: `${progress}%` }}
                        />
                        <span className="upload-notification__progress-text">
                            {progress}%
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Upload = () => {
    const [file, setFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadStatus, setUploadStatus] = useState('');
    const [selectedTeams, setSelectedTeams] = useState([]);
    const [activeFileName, setActiveFileName] = useState('');
    const fileInputRef = useRef(null);
    const wsRef = useRef(null);
    const { authToken } = useAuth();
    const { user, loading } = useUser();

    const connectWebSocket = () => {
        if (wsRef.current) return; // Don't create new connection if one exists

        let wsUrl;
        if (process.env.NODE_ENV === 'production') {
            wsUrl = baseUrl.replace('https://', 'wss://').replace('http://', 'ws://');
            wsUrl = `${wsUrl}/ws/${user.id}`;
        } else {
            wsUrl = `ws://localhost:8000/ws/${user.id}`;
        }
        
        const ws = new WebSocket(wsUrl);
        wsRef.current = ws;

        ws.onopen = () => {
        };

        ws.onmessage = (event) => {
            const message = JSON.parse(event.data);
            if (message.progress !== undefined) {
                setUploadProgress(message.progress);
            }
        };

        ws.onclose = () => {
            wsRef.current = null;
        };

        ws.onerror = (error) => {
            console.error(`WebSocket error for user ${user.id}:`, error);
            wsRef.current = null;
        };
    };

    const disconnectWebSocket = () => {
        if (wsRef.current) {
            wsRef.current.close();
            wsRef.current = null;
        }
    };

    useEffect(() => {
        return () => {
            disconnectWebSocket();
        };
    }, []);

    const handleTeamsChange = (newSelectedTeams) => {
        setUploadStatus('');
        setFile(null);
        setSelectedTeams(newSelectedTeams);
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            if (selectedTeams.length > 0) {
                handleUpload(selectedFile);
            } else {
                setUploadStatus('Please select at least one team before uploading.');
            }
        }
    };

    const handleUpload = async (selectedFile) => {
        if (!selectedFile || !user?.id || loading) return;
        if (selectedTeams.length === 0) {
            setUploadStatus('Please select at least one team before uploading.');
            return;
        }

        setUploadProgress(0);
        setUploadStatus('');
        setActiveFileName(selectedFile.name);

        // Connect WebSocket before starting upload
        connectWebSocket();

        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('client_id', user.id);
        formData.append('teams', JSON.stringify(selectedTeams));

        try {
            const response = await fetch(`${baseUrl}/upload_file/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${authToken}`
                },
                body: formData,
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.detail || 'Network response was not ok');
            }

            const result = await response.json();
            setUploadStatus(result.message);
        } catch (error) {
            console.error('Error uploading file:', error);
            setUploadStatus(`Error uploading file: ${error.message}`);
            setUploadProgress(0);
            disconnectWebSocket();
        } finally {
            setTimeout(() => {
                if (uploadProgress === 100) {
                    setUploadProgress(0);
                    setActiveFileName('');
                    // Disconnect WebSocket after upload is complete
                    disconnectWebSocket();
                }
            }, 3000);
        }
    };

    const handleDrop = (event) => {
        event.preventDefault();
        if (selectedTeams.length === 0) {
            setUploadStatus('Please select at least one team before uploading.');
            return;
        }
        const droppedFile = event.dataTransfer.files[0];
        if (droppedFile) {
            setFile(droppedFile);
            handleUpload(droppedFile);
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleClick = () => {
        if (selectedTeams.length === 0) {
            setUploadStatus('Please select at least one team before uploading.');
            return;
        }
        fileInputRef.current?.click();
    };

    if (loading) {
        return <div className="upload__loading">Loading...</div>;
    }

    return (
        <>
            <div className="upload-container">
                <div className="upload">
                    <h1 className="upload__title">
                        Upload an audio, video, or document file to generate insights
                    </h1>
                    <p className="upload__subtitle">
                        Audio/video files will be transcribed in 10-15 mins. Documents will be processed immediately.
                    </p>

                    <div className="upload__teams-section">
                        <p className="upload__teams-title">
                            Select the teams to share this meeting's data with:
                        </p>
                        <TeamSelector 
                            selectedTeams={selectedTeams}
                            onTeamsChange={handleTeamsChange}
                        />
                    </div>

                    <div 
                        className={`upload__dropzone ${selectedTeams.length === 0 ? 'upload__dropzone--disabled' : ''}`}
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                        onClick={handleClick}
                    >
                        <div className="upload__icon">↑</div>
                        <p className="upload__dropzone-text">
                            Drag and drop MP3, M4A, WAV, MP4, PDF, DOCX, or TXT files here, or select files to upload.
                        </p>
                        <input 
                            type="file" 
                            ref={fileInputRef}
                            onChange={handleFileChange} 
                            className="upload__file-input" 
                            accept=".mp3,.m4a,.wav,.mp4,.pdf,.docx,.txt"
                            disabled={selectedTeams.length === 0}
                        />
                    </div>

                    {uploadStatus && (
                        <div className={`upload__status ${
                            uploadStatus.includes('Error') ? 'upload__status--error' : ''
                        }`}>
                            {uploadStatus}
                        </div>
                    )}
                </div>
            </div>
            <UploadProgressNotification 
                progress={uploadProgress}
                fileName={activeFileName}
            />
        </>
    );
};

export default Upload;