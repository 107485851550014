import React, { useState, useRef, useEffect, useCallback } from 'react';
import { fetchQueryResults } from '../../services/search';
import './MeetingInsightsSearch.css';
import { useAuth } from '../../hooks/useAuth';
import { useSearchParams } from 'react-router-dom';

const MeetingInsightsSearch = () => {
  const [query, setQuery] = useState('');
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const { authToken } = useAuth();
  const [searchParams] = useSearchParams();

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  const exampleQueries = [
    { text: "What does our product 'X' do", icon: "💡" },
    { text: "What has been discussed in the design meeting yesterday?", icon: "📦" },
  ];

  const handleSearch = useCallback(async (searchQuery) => {
    if (!searchQuery.trim()) return;

    setLoading(true);
    
    // Create a concatenated history string from previous messages
    const historyString = messages.length > 0 
      ? messages.map(msg => 
          `${msg.type === 'user' ? 'Human' : 'Assistant'}: ${msg.content}`
        ).join('\n\n')
      : null;

    setMessages((prevMessages) => [...prevMessages, { type: 'user', content: searchQuery }]);

    try {
      const data = await fetchQueryResults({
        query: searchQuery,
        chat_history: historyString
      }, authToken);
      const analysisContent = data.analysis || 'No analysis available.';
      setMessages((prevMessages) => [
        ...prevMessages,
        { type: 'assistant', content: analysisContent }
      ]);
    } catch (error) {
      setMessages((prevMessages) => [
        ...prevMessages,
        { type: 'assistant', content: 'Error fetching query results. Please try again.' }
      ]);
    }

    setLoading(false);
    setQuery('');
  }, [authToken, messages]);

  const handleExampleQuery = useCallback((text) => {
    setQuery(text);
    handleSearch(text);
  }, [handleSearch]);

  // Load query from URL and set it
  useEffect(() => {
    const initialQuery = searchParams.get('query');
    if (initialQuery && initialQuery.trim()) {
      setQuery(initialQuery.trim());
      handleSearch(initialQuery.trim());
    }
  }, [searchParams, handleSearch]);

  return (
    <div className="chat-container">
      <div className="chat-messages">
        {messages.length === 0 && !loading && (
          <div className="welcome-message">
            <h1>Hello,</h1>
            <h2>How can I assist you today?</h2>
            <div className="example-queries">
              {exampleQueries.map((q, index) => (
                <div
                  key={index}
                  className="example-query"
                  onClick={() => handleExampleQuery(q.text)}
                >
                  <span>{q.icon}</span>
                  <p>{q.text}</p>
                </div>
              ))}
            </div>
          </div>
        )}
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.type}`}>
            <div 
              className="message-content"
              dangerouslySetInnerHTML={{ __html: message.content }}
            />
          </div>
        ))}
        {loading && (
          <div className="message assistant">
            <div className="message-content">
              <div className="thinking-dots">
                <div className="thinking-dot"></div>
                <div className="thinking-dot"></div>
                <div className="thinking-dot"></div>
              </div>
            </div>
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>
      <div className="chat-input-container">
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Enter a prompt here"
          className="chat-input"
          onKeyDown={(e) => e.key === 'Enter' && !e.shiftKey && handleSearch(query)}
        />
        <button 
          onClick={() => handleSearch(query)} 
          disabled={loading}
          className="chat-send-button"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="send-icon"
          >
            <path d="M3.478 2.405a.75.75 0 00-.926.94l2.432 7.905H13.5a.75.75 0 010 1.5H4.984l-2.432 7.905a.75.75 0 00.926.94 60.519 60.519 0 0018.445-8.986.75.75 0 000-1.218A60.517 60.517 0 003.478 2.405z" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default MeetingInsightsSearch;