import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchDocumentSummary } from '../../services/documents';
import { useAuth } from '../../hooks/useAuth';
import { formatDateTime } from '../../utils/dateUtils';
import './DocumentShortSummary.css';

const DocumentShortSummary = () => {
  const { id } = useParams();
  const { authToken } = useAuth();
  const [summaryData, setSummaryData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (id && authToken) {
      fetchDocumentSummary(id, authToken)
        .then(data => {
          setSummaryData(data);
          setLoading(false);
        })
        .catch(err => {
          setError(err.message);
          setLoading(false);
        });
    }
  }, [id, authToken]);

  if (loading) {
    return <div className="document-short-summary__loading">Loading...</div>;
  }

  if (error) {
    return <div className="document-short-summary__error">{error}</div>;
  }

  return (
    <div className="document-short-summary">
      <div className="document-short-summary__card">
        <div className="document-short-summary__metadata">
          <span>{formatDateTime(summaryData.timestamp)}</span>
        </div>

        <div className="document-short-summary__section">
          <h2 className="document-short-summary__section-title">Document Summary</h2>
          {summaryData.summary?.summary && (
            <div className="document-short-summary__text">
              {summaryData.summary.summary}
            </div>
          )}
        </div>

        {summaryData.summary?.importants && (
          <div className="document-short-summary__section">
            <h2 className="document-short-summary__section-title">Key Points</h2>
            {summaryData.summary.importants.map((important, index) => (
              <div key={index} className="document-short-summary__note">
                <div className="document-short-summary__subtitle">{important.header}</div>
                <ul className="document-short-summary__list">
                  {important.subitems.map((subitem, subIndex) => (
                    <li key={subIndex} className="document-short-summary__list-item">
                      {subitem}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default DocumentShortSummary; 