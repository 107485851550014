export const formatDateTime = (timestamp) => {
  if (!timestamp) return '';
  
  const date = new Date(Number(timestamp) * 1000);
  
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  }).format(date);
}; 