export const brainSvg = `<svg viewBox="-2 -2 584.2 508.7" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" overflow="hidden">
<g xmlns="http://www.w3.org/2000/svg">
<rect x="5.1" y="210.1" width="1.6" height="26.7" class="brainRect"/>
<path d="M5.9 211.2C2.6 211.2 0 208.5 0 205.3 0 202 2.7 199.4 5.9 199.4 9.2 199.4 11.8 202.1 11.8 205.3 11.9 208.6 9.2 211.2 5.9 211.2ZM5.9 200.9C3.5 200.9 1.6 202.9 1.6 205.2 1.6 207.6 3.6 209.5 5.9 209.5 8.3 209.5 10.2 207.5 10.2 205.2 10.3 202.9 8.3 200.9 5.9 200.9Z" class="brainPath"/>
<path d="M141.6 113C138.3 113 135.7 110.3 135.7 107.1 135.7 103.8 138.4 101.2 141.6 101.2 144.9 101.2 147.5 103.9 147.5 107.1 147.5 110.3 144.8 113 141.6 113ZM141.6 102.7C139.2 102.7 137.3 104.7 137.3 107 137.3 109.3 139.3 111.3 141.6 111.3 144 111.3 145.9 109.3 145.9 107 145.9 104.7 144 102.7 141.6 102.7Z" class="brainPath"/>
<path d="M112.5 185.9C109.2 185.9 106.6 183.2 106.6 180 106.6 176.7 109.3 174.1 112.5 174.1 115.8 174.1 118.4 176.8 118.4 180 118.4 183.3 115.7 185.9 112.5 185.9ZM112.5 175.7C110.1 175.7 108.2 177.7 108.2 180 108.2 182.4 110.2 184.3 112.5 184.3 114.9 184.3 116.8 182.3 116.8 180 116.8 177.6 114.9 175.7 112.5 175.7Z" class="brainPath"/>
<path d="M199.2 185.3C194.8 185.3 191.2 181.7 191.2 177.3 191.2 172.9 194.8 169.3 199.2 169.3 203.6 169.3 207.2 172.9 207.2 177.3 207.2 181.7 203.6 185.3 199.2 185.3ZM199.2 170.8C195.7 170.8 192.8 173.7 192.8 177.2 192.8 180.7 195.7 183.6 199.2 183.6 202.7 183.6 205.6 180.7 205.6 177.2 205.6 173.7 202.7 170.8 199.2 170.8Z" class="brainPath"/>
<path d="M237.8 133.6C233.4 133.6 229.8 130 229.8 125.6 229.8 121.2 233.4 117.6 237.8 117.6 242.2 117.6 245.8 121.2 245.8 125.6 245.8 130 242.3 133.6 237.8 133.6ZM237.8 119.2C234.3 119.2 231.4 122.1 231.4 125.6 231.4 129.1 234.3 132 237.8 132 241.3 132 244.2 129.1 244.2 125.6 244.3 122.1 241.4 119.2 237.8 119.2Z" class="brainPath"/>
<path d="M239.9 20.9C236.9 20.9 234.5 18.5 234.5 15.5 234.5 12.5 236.9 10.1 239.9 10.1 242.9 10.1 245.3 12.5 245.3 15.5 245.3 18.5 242.8 20.9 239.9 20.9ZM239.9 11.8C237.8 11.8 236.1 13.5 236.1 15.6 236.1 17.7 237.8 19.4 239.9 19.4 242 19.4 243.7 17.7 243.7 15.6 243.7 13.5 242 11.8 239.9 11.8Z" class="brainPath"/>
<path d="M285.3 57C282.3 57 279.9 54.6 279.9 51.6 279.9 48.6 282.3 46.2 285.3 46.2 288.3 46.2 290.7 48.6 290.7 51.6 290.7 54.6 288.3 57 285.3 57ZM285.3 47.8C283.2 47.8 281.5 49.5 281.5 51.6 281.5 53.7 283.2 55.4 285.3 55.4 287.4 55.4 289.1 53.7 289.1 51.6 289.1 49.5 287.4 47.8 285.3 47.8Z" class="brainPath"/>
<path d="M292.6 85.3C291.6 85.3 290.5 85 289.7 84.4 288.5 83.6 287.7 82.4 287.4 81 287.1 79.6 287.4 78.2 288.2 77 289 75.8 290.2 75 291.6 74.7 293 74.4 294.4 74.7 295.6 75.5L295.6 75.5C298.1 77.1 298.8 80.5 297.1 82.9 296.3 84.1 295.1 84.9 293.7 85.2 293.4 85.3 293 85.3 292.6 85.3ZM292.6 76.2C292.3 76.2 292.1 76.2 291.8 76.3 290.8 76.5 290 77.1 289.4 77.9 288.8 78.7 288.7 79.8 288.9 80.7 289.1 81.7 289.7 82.5 290.5 83.1 291.3 83.7 292.4 83.8 293.3 83.6 294.3 83.4 295.1 82.8 295.7 82 296.8 80.3 296.4 77.9 294.6 76.8 294.1 76.4 293.4 76.2 292.6 76.2Z" class="brainPath"/>
<path d="M268 119.1C267 119.1 266 118.8 265.1 118.2 262.6 116.6 261.9 113.2 263.6 110.8 264.4 109.6 265.6 108.8 267 108.5 268.4 108.2 269.8 108.5 271 109.3 273.5 110.9 274.2 114.3 272.5 116.7 271.4 118.3 269.7 119.1 268 119.1ZM268 110C266.8 110 265.6 110.6 264.8 111.7 263.7 113.4 264.1 115.8 265.9 116.9 267.6 118 270 117.6 271.1 115.8 272.2 114.1 271.8 111.7 270 110.6L270 110.6C269.4 110.2 268.7 110 268 110Z" class="brainPath"/>
<path d="M338.1 18.2C335.1 18.2 332.7 15.8 332.7 12.8 332.7 9.8 335.1 7.4 338.1 7.4 341.1 7.4 343.5 9.8 343.5 12.8 343.5 15.8 341 18.2 338.1 18.2ZM338.1 9C336 9 334.3 10.7 334.3 12.8 334.3 14.9 336 16.6 338.1 16.6 340.2 16.6 341.9 14.9 341.9 12.8 341.9 10.7 340.2 9 338.1 9Z" class="brainPath"/>
<path d="M363.4 108.8C360.4 108.8 358 106.4 358 103.4 358 100.4 360.4 98 363.4 98 366.4 98 368.8 100.4 368.8 103.4 368.8 106.4 366.4 108.8 363.4 108.8ZM363.4 99.7C361.3 99.7 359.6 101.4 359.6 103.5 359.6 105.6 361.3 107.3 363.4 107.3 365.5 107.3 367.2 105.6 367.2 103.5 367.2 101.4 365.5 99.7 363.4 99.7Z" class="brainPath"/>
<path d="M412.8 121.2C409.8 121.2 407.4 118.8 407.4 115.8 407.4 112.8 409.8 110.4 412.8 110.4 415.8 110.4 418.2 112.8 418.2 115.8 418.1 118.8 415.7 121.2 412.8 121.2ZM412.8 112C410.7 112 409 113.7 409 115.8 409 117.9 410.7 119.6 412.8 119.6 414.9 119.6 416.6 117.9 416.6 115.8 416.5 113.7 414.8 112 412.8 112Z" class="brainPath"/>
<path d="M493.3 187.7C490.3 187.7 487.9 185.3 487.9 182.3 487.9 179.3 490.3 176.9 493.3 176.9 496.3 176.9 498.7 179.3 498.7 182.3 498.7 185.3 496.2 187.7 493.3 187.7ZM493.3 178.6C491.2 178.6 489.5 180.3 489.5 182.4 489.5 184.5 491.2 186.2 493.3 186.2 495.4 186.2 497.1 184.5 497.1 182.4 497.1 180.3 495.4 178.6 493.3 178.6Z" class="brainPath"/>
<path d="M495.3 233.5C492.3 233.5 489.9 231.1 489.9 228.1 489.9 225.1 492.3 222.7 495.3 222.7 498.3 222.7 500.7 225.1 500.7 228.1 500.7 231.1 498.3 233.5 495.3 233.5ZM495.3 224.4C493.2 224.4 491.5 226.1 491.5 228.2 491.5 230.3 493.2 232 495.3 232 497.4 232 499.1 230.3 499.1 228.2 499.1 226.1 497.4 224.4 495.3 224.4Z" class="brainPath"/>
<path d="M515.6 279.5C512.6 279.5 510.2 277.1 510.2 274.1 510.2 271.1 512.6 268.7 515.6 268.7 518.6 268.7 521 271.1 521 274.1 521 277.1 518.6 279.5 515.6 279.5ZM515.6 270.4C513.5 270.4 511.8 272.1 511.8 274.2 511.8 276.3 513.5 278 515.6 278 517.7 278 519.4 276.3 519.4 274.2 519.4 272.1 517.7 270.4 515.6 270.4Z" class="brainPath"/>
<path d="M517.6 136.3C514.6 136.3 512.2 133.9 512.2 130.9 512.2 127.9 514.6 125.5 517.6 125.5 520.6 125.5 523 127.9 523 130.9 523 133.9 520.5 136.3 517.6 136.3ZM517.6 127.2C515.5 127.2 513.8 128.9 513.8 131 513.8 133.1 515.5 134.8 517.6 134.8 519.7 134.8 521.4 133.1 521.4 131 521.4 128.9 519.7 127.2 517.6 127.2Z" class="brainPath"/>
<path d="M542.2 164.6C540 164.6 538.3 162.8 538.3 160.7 538.3 158.5 540.1 156.8 542.2 156.8 544.4 156.8 546.1 158.6 546.1 160.7 546.2 162.9 544.4 164.6 542.2 164.6ZM542.2 158.3C540.9 158.3 539.8 159.4 539.8 160.7 539.8 162 540.9 163.1 542.2 163.1 543.5 163.1 544.6 162 544.6 160.7 544.6 159.4 543.5 158.3 542.2 158.3Z" class="brainPath"/>
<path d="M427.1 101C424.1 101 421.7 98.6 421.7 95.6 421.7 92.6 424.1 90.2 427.1 90.2 430.1 90.2 432.5 92.6 432.5 95.6 432.5 98.6 430.1 101 427.1 101ZM427.1 91.8C425 91.8 423.3 93.5 423.3 95.6 423.3 97.7 425 99.4 427.1 99.4 429.2 99.4 430.9 97.7 430.9 95.6 430.9 93.5 429.2 91.8 427.1 91.8Z" class="brainPath"/>
<path d="M425.4 74.2C422.4 74.2 420 71.8 420 68.8 420 65.8 422.4 63.4 425.4 63.4 428.4 63.4 430.8 65.8 430.8 68.8 430.8 71.8 428.4 74.2 425.4 74.2ZM425.4 65.1C423.3 65.1 421.6 66.8 421.6 68.9 421.6 71 423.3 72.7 425.4 72.7 427.5 72.7 429.2 71 429.2 68.9 429.2 66.8 427.5 65.1 425.4 65.1Z" class="brainPath"/>
<path d="M456.2 67.5C453.2 67.5 450.8 65.1 450.8 62.1 450.8 59.1 453.2 56.7 456.2 56.7 459.2 56.7 461.6 59.1 461.6 62.1 461.6 65.1 459.2 67.5 456.2 67.5ZM456.2 58.3C454.1 58.3 452.4 60 452.4 62.1 452.4 64.2 454.1 65.9 456.2 65.9 458.3 65.9 460 64.2 460 62.1 460 60 458.3 58.3 456.2 58.3Z" class="brainPath"/>
<path d="M482.7 109.1C480.5 109.1 478.8 107.3 478.8 105.2 478.8 103.1 480.6 101.3 482.7 101.3 484.9 101.3 486.6 103.1 486.6 105.2 486.6 107.3 484.9 109.1 482.7 109.1ZM482.7 102.9C481.4 102.9 480.4 103.9 480.4 105.2 480.4 106.5 481.4 107.5 482.7 107.5 484 107.5 485 106.5 485 105.2 485 103.9 484 102.9 482.7 102.9Z" class="brainPath"/>
<path d="M496.2 149.8C494 149.8 492.3 148 492.3 145.9 492.3 143.7 494.1 142 496.2 142 498.4 142 500.1 143.8 500.1 145.9 500.1 148 498.4 149.8 496.2 149.8ZM496.2 143.5C494.9 143.5 493.9 144.5 493.9 145.8 493.9 147.1 494.9 148.1 496.2 148.1 497.5 148.1 498.5 147.1 498.5 145.8 498.6 144.6 497.5 143.5 496.2 143.5Z" class="brainPath"/>
<path d="M391.2 46.5C388.2 46.5 385.8 44.1 385.8 41.1 385.8 38.1 388.2 35.7 391.2 35.7 394.2 35.7 396.6 38.1 396.6 41.1 396.5 44.1 394.1 46.5 391.2 46.5ZM391.2 37.3C389.1 37.3 387.4 39 387.4 41.1 387.4 43.2 389.1 44.9 391.2 44.9 393.3 44.9 395 43.2 395 41.1 395 39 393.3 37.3 391.2 37.3Z" class="brainPath"/>
<path d="M355.3 139.7C352.3 139.7 349.9 137.3 349.9 134.3 349.9 131.3 352.3 128.9 355.3 128.9 358.3 128.9 360.7 131.3 360.7 134.3 360.7 137.3 358.3 139.7 355.3 139.7ZM355.3 130.6C353.2 130.6 351.5 132.3 351.5 134.4 351.5 136.5 353.2 138.2 355.3 138.2 357.4 138.2 359.1 136.5 359.1 134.4 359.1 132.3 357.4 130.6 355.3 130.6Z" class="brainPath"/>
<path d="M414.9 43.1C411.9 43.1 409.5 40.7 409.5 37.7 409.5 34.7 411.9 32.3 414.9 32.3 417.9 32.3 420.3 34.7 420.3 37.7 420.3 40.7 417.9 43.1 414.9 43.1ZM414.9 34C412.8 34 411.1 35.7 411.1 37.8 411.1 39.9 412.8 41.6 414.9 41.6 417 41.6 418.7 39.9 418.7 37.8 418.7 35.7 417 34 414.9 34Z" class="brainPath"/>
<path d="M371.8 136.1C370.8 136.1 369.9 135.8 369 135.3 367.8 134.6 366.9 133.4 366.6 132 366.3 130.6 366.5 129.2 367.2 127.9 367.9 126.7 369.1 125.8 370.5 125.5 371.9 125.2 373.3 125.4 374.6 126.1 375.8 126.8 376.7 128 377 129.4 377.3 130.8 377.1 132.2 376.4 133.5 375.7 134.7 374.5 135.6 373.1 135.9 372.7 136 372.2 136.1 371.8 136.1ZM371.8 126.9C371.5 126.9 371.2 126.9 370.9 127 369.9 127.2 369.1 127.9 368.6 128.7 368.1 129.6 367.9 130.6 368.2 131.6 368.4 132.6 369.1 133.4 369.9 133.9 370.8 134.4 371.8 134.6 372.8 134.3 373.8 134.1 374.6 133.4 375.1 132.6 375.6 131.7 375.8 130.7 375.5 129.7L375.5 129.7C375.3 128.7 374.6 127.9 373.8 127.4 373.2 127.1 372.5 126.9 371.8 126.9Z" class="brainPath"/>
<path d="M384.8 157C383.1 157 381.5 156.2 380.5 154.8 378.7 152.4 379.3 149 381.6 147.3 382.8 146.4 384.2 146.1 385.6 146.3 387 146.5 388.3 147.3 389.1 148.4L389.1 148.4C390 149.6 390.3 151 390.1 152.4 389.9 153.8 389.1 155.1 388 155.9 387.1 156.6 385.9 157 384.8 157ZM384.8 147.8C384 147.8 383.2 148 382.6 148.5 380.9 149.7 380.6 152.1 381.8 153.8 383 155.5 385.4 155.8 387.1 154.6 387.9 154 388.4 153.1 388.6 152.1 388.8 151.1 388.5 150.1 387.9 149.3L387.9 149.3C387.1 148.3 386 147.8 384.8 147.8Z" class="brainPath"/>
<path d="M402 183C399.7 183 397.5 181.5 396.8 179.1 396 176.3 397.6 173.3 400.5 172.4 401.9 172 403.3 172.2 404.6 172.8 405.9 173.5 406.8 174.6 407.2 176 407.6 177.4 407.4 178.8 406.8 180.1 406.1 181.4 405 182.3 403.6 182.7 403 182.9 402.5 183 402 183ZM402 173.8C401.6 173.8 401.3 173.9 400.9 174 398.9 174.6 397.7 176.7 398.3 178.7 398.9 180.7 401 181.9 403 181.3 404 181 404.8 180.4 405.3 179.5 405.8 178.6 405.9 177.6 405.6 176.6 405.3 175.6 404.7 174.8 403.8 174.3 403.2 174 402.6 173.8 402 173.8Z" class="brainPath"/>
<path d="M410.2 205.2C409.3 205.2 408.4 205 407.6 204.5 406.3 203.8 405.4 202.7 405 201.3 404.2 198.5 405.8 195.5 408.7 194.6 411.5 193.8 414.5 195.4 415.4 198.3L415.4 198.3C415.8 199.7 415.6 201.1 415 202.4 414.3 203.7 413.2 204.6 411.8 205 411.2 205.1 410.7 205.2 410.2 205.2ZM410.2 196C409.9 196 409.5 196 409.1 196.2 407.1 196.8 405.9 198.9 406.5 200.9 406.8 201.9 407.4 202.7 408.3 203.2 409.2 203.7 410.2 203.8 411.2 203.5 412.2 203.2 413 202.6 413.5 201.7 414 200.8 414.1 199.8 413.8 198.8L413.8 198.8C413.3 197.1 411.8 196 410.2 196Z" class="brainPath"/>
<path d="M414.5 245.8C412.8 245.8 411.2 245 410.1 243.5 408.4 241.1 409 237.7 411.4 236 413.8 234.3 417.2 234.9 418.9 237.3 420.6 239.7 420 243.1 417.6 244.8 416.7 245.5 415.6 245.8 414.5 245.8ZM414.5 236.6C413.7 236.6 413 236.8 412.3 237.3 410.6 238.5 410.2 240.9 411.4 242.6 412.6 244.3 415 244.7 416.7 243.5 418.4 242.3 418.8 239.9 417.6 238.2 416.9 237.2 415.7 236.6 414.5 236.6Z" class="brainPath"/>
<path d="M435.5 252.9C435.2 252.9 434.9 252.9 434.6 252.8 433.2 252.6 432 251.8 431.1 250.6 429.4 248.2 430 244.8 432.4 243.1 433.6 242.3 435 242 436.4 242.2 437.8 242.4 439 243.2 439.9 244.4 440.7 245.6 441 247 440.8 248.4 440.6 249.8 439.8 251 438.6 251.9 437.7 252.5 436.6 252.9 435.5 252.9ZM435.5 243.7C434.7 243.7 434 243.9 433.3 244.4 431.6 245.6 431.2 248 432.4 249.7 433 250.5 433.9 251.1 434.9 251.2 435.9 251.4 436.9 251.1 437.7 250.6 438.5 250 439.1 249.1 439.2 248.1 439.4 247.1 439.1 246.1 438.6 245.3L438.6 245.3C437.9 244.3 436.7 243.7 435.5 243.7Z" class="brainPath"/>
<path d="M496 277.6C494.3 277.6 492.7 276.8 491.6 275.3 490.8 274.1 490.5 272.7 490.7 271.3 490.9 269.9 491.7 268.7 492.9 267.8 495.3 266.1 498.7 266.7 500.4 269.1 501.2 270.3 501.5 271.7 501.3 273.1 501.1 274.5 500.3 275.7 499.1 276.6 498.1 277.3 497.1 277.6 496 277.6ZM496 268.4C495.2 268.4 494.5 268.6 493.8 269.1 493 269.7 492.4 270.6 492.3 271.6 492.1 272.6 492.4 273.6 492.9 274.4 494.1 276.1 496.5 276.5 498.2 275.3 499 274.7 499.6 273.8 499.7 272.8 499.9 271.8 499.6 270.8 499.1 270 498.5 269.2 497.6 268.6 496.6 268.5 496.4 268.5 496.2 268.4 496 268.4Z" class="brainPath"/>
<path d="M447.6 332.6C445.9 332.6 444.3 331.8 443.2 330.3 441.5 327.9 442.1 324.5 444.5 322.8 446.9 321.1 450.3 321.7 452 324.1L452 324.1C452.8 325.3 453.1 326.7 452.9 328.1 452.7 329.5 451.9 330.7 450.7 331.6 449.7 332.3 448.6 332.6 447.6 332.6ZM447.6 323.5C446.8 323.5 446.1 323.7 445.4 324.2 443.7 325.4 443.3 327.8 444.5 329.5 445.1 330.3 446 330.9 447 331 448 331.2 449 330.9 449.8 330.4 450.6 329.8 451.2 328.9 451.3 327.9 451.5 326.9 451.2 325.9 450.7 325.1L450.7 325.1C449.9 324 448.8 323.5 447.6 323.5Z" class="brainPath"/>
<path d="M463 402.9C462.7 402.9 462.4 402.9 462.1 402.8 460.7 402.6 459.5 401.8 458.6 400.6 456.9 398.2 457.5 394.8 459.9 393.1 461.1 392.3 462.5 392 463.9 392.2 465.3 392.4 466.5 393.2 467.4 394.4 469.1 396.8 468.5 400.2 466.1 401.9 465.2 402.5 464.1 402.9 463 402.9ZM463 393.7C462.2 393.7 461.5 393.9 460.8 394.4 459.1 395.6 458.7 398 459.9 399.7 461.1 401.4 463.5 401.8 465.2 400.6 466 400 466.6 399.1 466.7 398.1 466.9 397.1 466.6 396.1 466.1 395.3L466.1 395.3C465.5 394.5 464.6 393.9 463.6 393.8 463.4 393.7 463.2 393.7 463 393.7Z" class="brainPath"/>
<path d="M554.1 354.4C553.8 354.4 553.4 354.4 553.1 354.3 551.5 354 550.2 353.2 549.2 351.9 547.3 349.2 547.9 345.5 550.6 343.6 553.3 341.7 557 342.3 558.9 345 559.8 346.3 560.2 347.9 559.9 349.5 559.6 351.1 558.8 352.4 557.5 353.4 556.5 354.1 555.3 354.4 554.1 354.4ZM554 344.1C553.1 344.1 552.2 344.4 551.5 344.9 549.5 346.3 549.1 349 550.4 351 551.1 352 552.1 352.6 553.2 352.8 554.4 353 555.5 352.7 556.5 352.1 557.5 351.4 558.1 350.4 558.3 349.3 558.5 348.1 558.2 347 557.6 346L557.6 346C556.8 344.7 555.4 344.1 554 344.1Z" class="brainPath"/>
<path d="M424.5 392.2C422.8 392.2 421.2 391.4 420.1 389.9 418.4 387.5 419 384.1 421.4 382.4 423.8 380.7 427.2 381.3 428.9 383.7L428.9 383.7C430.6 386.1 430 389.5 427.6 391.2 426.7 391.9 425.6 392.2 424.5 392.2ZM424.5 383.1C423.7 383.1 423 383.3 422.3 383.8 421.5 384.4 420.9 385.3 420.8 386.3 420.6 387.3 420.9 388.3 421.4 389.1 422.6 390.8 425 391.2 426.7 390 428.4 388.8 428.8 386.4 427.6 384.7L427.6 384.7C426.9 383.6 425.7 383.1 424.5 383.1Z" class="brainPath"/>
<path d="M402.2 503.9C401.9 503.9 401.6 503.9 401.3 503.8 399.9 503.6 398.7 502.8 397.8 501.6 396.1 499.2 396.7 495.8 399.1 494.1 401.5 492.4 404.9 493 406.6 495.4L406.6 495.4C408.3 497.8 407.7 501.2 405.3 502.9 404.4 503.6 403.3 503.9 402.2 503.9ZM402.2 494.7C401.4 494.7 400.7 494.9 400 495.4 399.2 496 398.6 496.9 398.5 497.9 398.3 498.9 398.6 499.9 399.1 500.7 399.7 501.5 400.6 502.1 401.6 502.2 402.6 502.4 403.6 502.1 404.4 501.6 406.1 500.4 406.5 498 405.3 496.3L405.3 496.3C404.6 495.3 403.4 494.7 402.2 494.7Z" class="brainPath"/>
<path d="M388.3 440.6C388.1 440.6 387.8 440.6 387.6 440.5 386.6 440.3 385.6 439.7 385 438.9 383.7 437.1 384.2 434.6 386 433.4 386.9 432.8 387.9 432.5 389 432.7 390 432.9 391 433.5 391.6 434.3L391.6 434.3C392.9 436.1 392.4 438.6 390.6 439.8 389.9 440.4 389.1 440.6 388.3 440.6ZM388.3 434.2C387.8 434.2 387.3 434.4 386.9 434.6 386.4 435 386 435.5 385.9 436.2 385.8 436.8 385.9 437.5 386.3 438 386.7 438.5 387.2 438.9 387.9 439 388.5 439.1 389.2 439 389.7 438.6 390.8 437.8 391 436.3 390.3 435.3L390.3 435.3C389.9 434.8 389.4 434.4 388.7 434.3 388.6 434.2 388.4 434.2 388.3 434.2Z" class="brainPath"/>
<path d="M341.2 470.2C341 470.2 340.7 470.2 340.5 470.1 339.5 469.9 338.5 469.3 337.9 468.5 336.6 466.7 337.1 464.2 338.9 463 339.8 462.4 340.8 462.1 341.9 462.3 342.9 462.5 343.9 463.1 344.5 463.9L344.5 463.9C345.8 465.7 345.3 468.2 343.5 469.4 342.8 469.9 342 470.2 341.2 470.2ZM341.1 463.8C340.6 463.8 340.1 463.9 339.7 464.2 339.2 464.6 338.8 465.1 338.7 465.8 338.6 466.4 338.7 467.1 339.1 467.6 339.5 468.1 340 468.5 340.7 468.6 341.3 468.7 342 468.6 342.5 468.2 343.6 467.4 343.8 465.9 343.1 464.9L343.1 464.9C342.6 464.1 341.9 463.8 341.1 463.8Z" class="brainPath"/>
<path d="M361.9 480C360.6 480 359.4 479.4 358.6 478.3 358 477.4 357.7 476.4 357.9 475.3 358.1 474.3 358.7 473.3 359.5 472.7 361.3 471.4 363.8 471.9 365 473.7L365 473.7C366.3 475.5 365.8 478 364 479.2 363.5 479.8 362.7 480 361.9 480ZM361.9 473.6C361.4 473.6 360.9 473.8 360.5 474 360 474.4 359.6 474.9 359.5 475.6 359.4 476.2 359.5 476.9 359.9 477.4 360.7 478.5 362.2 478.7 363.2 478 364.3 477.2 364.5 475.7 363.8 474.7L363.8 474.7C363.4 474.2 362.9 473.8 362.2 473.7 362.2 473.6 362.1 473.6 361.9 473.6Z" class="brainPath"/>
<path d="M389.5 361.3C387.8 361.3 386.2 360.5 385.1 359 384.3 357.8 384 356.4 384.2 355 384.4 353.6 385.2 352.4 386.4 351.5 388.8 349.8 392.2 350.4 393.9 352.8 394.7 354 395 355.4 394.8 356.8 394.6 358.2 393.8 359.4 392.6 360.3 391.6 360.9 390.5 361.3 389.5 361.3ZM389.5 352.1C388.7 352.1 388 352.3 387.3 352.8 386.5 353.4 385.9 354.3 385.8 355.3 385.6 356.3 385.9 357.3 386.4 358.1 387.6 359.8 390 360.2 391.7 359 392.5 358.4 393.1 357.5 393.2 356.5 393.4 355.5 393.1 354.5 392.6 353.7 392 352.9 391.1 352.3 390.1 352.2 389.9 352.1 389.7 352.1 389.5 352.1Z" class="brainPath"/>
<path d="M370.4 418.8C370.1 418.8 369.8 418.8 369.5 418.7 368.1 418.5 366.9 417.7 366 416.5 364.3 414.1 364.9 410.7 367.3 409 368.5 408.2 369.9 407.9 371.3 408.1 372.7 408.3 373.9 409.1 374.8 410.3L374.8 410.3C376.5 412.7 375.9 416.1 373.5 417.8 372.6 418.5 371.5 418.8 370.4 418.8ZM370.4 409.7C369.6 409.7 368.9 409.9 368.2 410.4 367.4 411 366.8 411.9 366.7 412.9 366.5 413.9 366.8 414.9 367.3 415.7 367.9 416.5 368.8 417.1 369.8 417.2 370.8 417.4 371.8 417.1 372.6 416.6 374.3 415.4 374.7 413 373.5 411.3L373.5 411.3C372.8 410.2 371.6 409.7 370.4 409.7Z" class="brainPath"/>
<path d="M496 290.8C494.3 290.8 492.7 290 491.6 288.5 489.9 286.1 490.5 282.7 492.9 281 495.3 279.3 498.7 279.9 500.4 282.3 502.1 284.7 501.5 288.1 499.1 289.8 498.1 290.5 497.1 290.8 496 290.8ZM496 281.7C495.2 281.7 494.5 281.9 493.8 282.4 492.1 283.6 491.7 286 492.9 287.7 494.1 289.4 496.5 289.8 498.2 288.6 499.9 287.4 500.3 285 499.1 283.3 498.3 282.2 497.2 281.7 496 281.7Z" class="brainPath"/>
<path d="M576.8 292.2C575.1 292.2 573.5 291.4 572.4 289.9 571.6 288.7 571.3 287.3 571.5 285.9 571.7 284.5 572.5 283.3 573.7 282.4 576.1 280.7 579.5 281.3 581.2 283.7 582.9 286.1 582.3 289.5 579.9 291.2 579 291.9 577.9 292.2 576.8 292.2ZM576.8 283.1C576 283.1 575.3 283.3 574.6 283.8 573.8 284.4 573.2 285.3 573.1 286.3 572.9 287.3 573.2 288.3 573.7 289.1 574.9 290.8 577.3 291.2 579 290 580.7 288.8 581.1 286.4 579.9 284.7 579.3 283.9 578.4 283.3 577.4 283.2 577.2 283.1 577 283.1 576.8 283.1Z" class="brainPath"/>
<path d="M575.2 247.7C573.5 247.7 571.9 246.9 570.8 245.4 570 244.2 569.7 242.8 569.9 241.4 570.1 240 570.9 238.8 572.1 237.9 574.5 236.2 577.9 236.8 579.6 239.2L579.6 239.2C580.4 240.4 580.7 241.8 580.5 243.2 580.3 244.6 579.5 245.8 578.3 246.7 577.4 247.4 576.3 247.7 575.2 247.7ZM575.2 238.5C574.4 238.5 573.7 238.7 573 239.2 572.2 239.8 571.6 240.7 571.5 241.7 571.3 242.7 571.6 243.7 572.1 244.5 572.7 245.3 573.6 245.9 574.6 246 575.6 246.2 576.6 245.9 577.4 245.4 579.1 244.2 579.5 241.8 578.3 240.1L578.3 240.1C577.7 239.3 576.8 238.7 575.8 238.6 575.6 238.5 575.4 238.5 575.2 238.5Z" class="brainPath"/>
<path d="M572.5 318.4C570.8 318.4 569.2 317.6 568.1 316.1 567.3 314.9 567 313.5 567.2 312.1 567.4 310.7 568.2 309.5 569.4 308.6 571.8 306.9 575.2 307.5 576.9 309.9L576.9 309.9C577.7 311.1 578 312.5 577.8 313.9 577.6 315.3 576.8 316.5 575.6 317.4 574.7 318.1 573.6 318.4 572.5 318.4ZM572.5 309.3C571.7 309.3 571 309.5 570.3 310 569.5 310.6 568.9 311.5 568.8 312.5 568.6 313.5 568.9 314.5 569.4 315.3 570.6 317 573 317.4 574.7 316.2 576.4 315 576.8 312.6 575.6 310.9L575.6 310.9C574.9 309.8 573.7 309.3 572.5 309.3Z" class="brainPath"/>
<path d="M515.6 317.7C513.9 317.7 512.3 316.9 511.2 315.4 509.5 313 510.1 309.6 512.5 307.9 514.9 306.2 518.3 306.8 520 309.2 520.8 310.4 521.1 311.8 520.9 313.2 520.7 314.6 519.9 315.8 518.7 316.7 517.7 317.4 516.6 317.7 515.6 317.7ZM515.6 308.6C514.8 308.6 514.1 308.8 513.4 309.3 511.7 310.5 511.3 312.9 512.5 314.6 513.7 316.3 516.1 316.7 517.8 315.5 518.6 314.9 519.2 314 519.3 313 519.5 312 519.2 311 518.7 310.2L518.7 310.2C517.9 309.1 516.7 308.6 515.6 308.6Z" class="brainPath"/>
<path d="M486.5 355.8C484.8 355.8 483.2 355 482.1 353.5 481.3 352.3 481 350.9 481.2 349.5 481.4 348.1 482.2 346.9 483.4 346 485.8 344.3 489.2 344.9 490.9 347.3 491.7 348.5 492 349.9 491.8 351.3 491.6 352.7 490.8 353.9 489.6 354.8 488.6 355.4 487.5 355.8 486.5 355.8ZM486.4 346.6C485.6 346.6 484.9 346.8 484.2 347.3 482.5 348.5 482.1 350.9 483.3 352.6 483.9 353.4 484.8 354 485.8 354.1 486.8 354.3 487.8 354 488.6 353.5 489.4 352.9 490 352 490.1 351 490.3 350 490 349 489.5 348.2 488.9 347.4 488 346.8 487 346.7 486.9 346.6 486.7 346.6 486.4 346.6Z" class="brainPath"/>
<path d="M494 379.6C492.3 379.6 490.7 378.8 489.6 377.3 487.9 374.9 488.5 371.5 490.9 369.8 493.3 368.1 496.7 368.7 498.4 371.1 500.1 373.5 499.5 376.9 497.1 378.6 496.1 379.2 495.1 379.6 494 379.6ZM494 370.4C493.2 370.4 492.5 370.6 491.8 371.1 490.1 372.3 489.7 374.7 490.9 376.4 492.1 378.1 494.5 378.5 496.2 377.3 497.9 376.1 498.3 373.7 497.1 372 496.3 371 495.2 370.4 494 370.4Z" class="brainPath"/>
<path d="M484.2 369.6C483.9 369.6 483.6 369.6 483.3 369.5 481.9 369.3 480.7 368.5 479.8 367.3 479 366.1 478.7 364.7 478.9 363.3 479.1 361.9 479.9 360.7 481.1 359.8 482.3 359 483.7 358.7 485.1 358.9 486.5 359.1 487.7 359.9 488.6 361.1L488.6 361.1C489.4 362.3 489.7 363.7 489.5 365.1 489.3 366.5 488.5 367.7 487.3 368.6 486.4 369.3 485.3 369.6 484.2 369.6ZM484.2 360.5C483.4 360.5 482.7 360.7 482 361.2 481.2 361.8 480.6 362.7 480.5 363.7 480.3 364.7 480.6 365.7 481.1 366.5 481.7 367.3 482.6 367.9 483.6 368 484.6 368.2 485.6 367.9 486.4 367.4 487.2 366.8 487.8 365.9 487.9 364.9 488.1 363.9 487.8 362.9 487.3 362.1L487.3 362.1C486.7 361.3 485.8 360.7 484.8 360.6 484.6 360.5 484.4 360.5 484.2 360.5Z" class="brainPath"/>
<path d="M395.6 229.1C394.7 229.1 393.8 228.9 393 228.4 391.7 227.7 390.8 226.6 390.4 225.2 390 223.8 390.2 222.4 390.8 221.1 391.5 219.8 392.6 218.9 394 218.5 395.4 218.1 396.8 218.3 398.1 218.9 399.4 219.6 400.3 220.7 400.7 222.1L400.7 222.1C401.1 223.5 400.9 224.9 400.3 226.2 399.6 227.5 398.5 228.4 397.1 228.8 396.6 229.1 396.1 229.1 395.6 229.1ZM395.6 220C395.2 220 394.9 220.1 394.5 220.2 393.5 220.5 392.7 221.1 392.2 222 391.7 222.9 391.6 223.9 391.9 224.9 392.2 225.9 392.8 226.7 393.7 227.2 394.6 227.7 395.6 227.8 396.6 227.5 397.6 227.2 398.4 226.6 398.9 225.7 399.4 224.8 399.5 223.8 399.2 222.8L399.2 222.8C398.9 221.8 398.3 221 397.4 220.5 396.8 220.1 396.2 220 395.6 220Z" class="brainPath"/>
<path d="M399.2 163.8C398.3 163.8 397.4 163.6 396.6 163.1 395.3 162.4 394.4 161.3 394 159.9 393.6 158.5 393.8 157.1 394.4 155.8 395.1 154.5 396.2 153.6 397.6 153.2 399 152.8 400.4 153 401.7 153.6 403 154.3 403.9 155.4 404.3 156.8 404.7 158.2 404.5 159.6 403.9 160.9 403.2 162.2 402.1 163.1 400.7 163.5 400.2 163.7 399.7 163.8 399.2 163.8ZM399.2 154.7C398.8 154.7 398.5 154.8 398.1 154.9 397.1 155.2 396.3 155.8 395.8 156.7 395.3 157.6 395.2 158.6 395.5 159.6 395.8 160.6 396.4 161.4 397.3 161.9 398.2 162.4 399.2 162.5 400.2 162.2 401.2 161.9 402 161.3 402.5 160.4 403 159.5 403.1 158.5 402.8 157.5 402.5 156.5 401.9 155.7 401 155.2 400.5 154.8 399.9 154.7 399.2 154.7Z" class="brainPath"/>
<path d="M365.3 249.8C364.6 249.8 364 249.7 363.4 249.4 360.6 248.3 359.3 245.2 360.3 242.5 360.8 241.2 361.8 240.1 363.1 239.5 364.4 238.9 365.9 238.9 367.2 239.4 370 240.5 371.3 243.6 370.3 246.3 369.8 247.6 368.8 248.7 367.5 249.3 366.8 249.6 366.1 249.8 365.3 249.8ZM365.3 240.6C363.8 240.6 362.3 241.5 361.8 243 361 244.9 362 247.1 364 247.9 365.9 248.7 368.1 247.7 368.9 245.7 369.7 243.8 368.7 241.6 366.7 240.8L366.7 240.8C366.2 240.7 365.8 240.6 365.3 240.6Z" class="brainPath"/>
<path d="M329.4 62C326.4 62 324 59.6 324 56.6 324 53.6 326.4 51.2 329.4 51.2 332.4 51.2 334.8 53.6 334.8 56.6 334.8 59.6 332.4 62 329.4 62ZM329.4 52.9C327.3 52.9 325.6 54.6 325.6 56.7 325.6 58.8 327.3 60.5 329.4 60.5 331.5 60.5 333.2 58.8 333.2 56.7 333.2 54.6 331.5 52.9 329.4 52.9Z" class="brainPath"/>
<path d="M246.8 325.7C242.4 325.7 238.8 322.1 238.8 317.7 238.8 313.3 242.4 309.7 246.8 309.7 251.2 309.7 254.8 313.3 254.8 317.7 254.8 322.1 251.2 325.7 246.8 325.7ZM246.8 311.2C243.3 311.2 240.4 314.1 240.4 317.6 240.4 321.1 243.3 324 246.8 324 250.3 324 253.2 321.1 253.2 317.6 253.2 314.1 250.3 311.2 246.8 311.2Z" class="brainPath"/>
<path d="M134.4 333.6C129.4 333.6 125.3 329.5 125.3 324.5 125.3 319.5 129.4 315.4 134.4 315.4 139.4 315.4 143.5 319.5 143.5 324.5 143.5 329.5 139.4 333.6 134.4 333.6ZM134.4 317.1C130.3 317.1 126.9 320.5 126.9 324.6 126.9 328.7 130.3 332.1 134.4 332.1 138.5 332.1 141.9 328.7 141.9 324.6 141.9 320.4 138.6 317.1 134.4 317.1Z" class="brainPath"/>
<path d="M282.6 290.4C278.2 290.4 274.6 286.8 274.6 282.4 274.6 278 278.2 274.4 282.6 274.4 287 274.4 290.6 278 290.6 282.4 290.6 286.8 287 290.4 282.6 290.4ZM282.6 276C279.1 276 276.2 278.9 276.2 282.4 276.2 285.9 279.1 288.8 282.6 288.8 286.1 288.8 289 285.9 289 282.4 289 278.9 286.1 276 282.6 276Z" class="brainPath"/>
<path d="M167.7 27.3C164.4 27.3 161.8 24.6 161.8 21.4 161.8 18.1 164.5 15.5 167.7 15.5 171 15.5 173.6 18.2 173.6 21.4 173.6 24.6 171 27.3 167.7 27.3ZM167.7 17C165.3 17 163.4 19 163.4 21.3 163.4 23.7 165.4 25.6 167.7 25.6 170.1 25.6 172 23.6 172 21.3 172 19 170.1 17 167.7 17Z" class="brainPath"/>
<path d="M5.9 247.5C2.6 247.5 0 244.8 0 241.6 0 238.3 2.7 235.7 5.9 235.7 9.2 235.7 11.8 238.4 11.8 241.6 11.9 244.9 9.2 247.5 5.9 247.5ZM5.9 237.3C3.5 237.3 1.6 239.3 1.6 241.6 1.6 244 3.6 245.9 5.9 245.9 8.3 245.9 10.2 243.9 10.2 241.6 10.3 239.2 8.3 237.3 5.9 237.3Z" class="brainPath"/>
<path d="M192.1 394.5C188.8 394.5 186.2 391.8 186.2 388.6 186.2 385.3 188.9 382.7 192.1 382.7 195.4 382.7 198 385.4 198 388.6 198 391.8 195.4 394.5 192.1 394.5ZM192.1 384.2C189.7 384.2 187.8 386.2 187.8 388.5 187.8 390.9 189.8 392.8 192.1 392.8 194.5 392.8 196.4 390.8 196.4 388.5 196.4 386.2 194.5 384.2 192.1 384.2Z" class="brainPath"/>
<path d="M222.9 368.3C219.6 368.3 217 365.6 217 362.4 217 359.1 219.7 356.5 222.9 356.5 226.2 356.5 228.8 359.2 228.8 362.4 228.9 365.6 226.2 368.3 222.9 368.3ZM222.9 358C220.5 358 218.6 360 218.6 362.3 218.6 364.7 220.6 366.6 222.9 366.6 225.3 366.6 227.2 364.6 227.2 362.3 227.3 359.9 225.3 358 222.9 358Z" class="brainPath"/>
<path d="M27.9 272.9C25.6 272.9 23.7 271 23.7 268.7 23.7 266.4 25.6 264.5 27.9 264.5 30.2 264.5 32.1 266.4 32.1 268.7 32.1 271 30.2 272.9 27.9 272.9ZM27.9 266C26.4 266 25.3 267.2 25.3 268.6 25.3 270.1 26.5 271.2 27.9 271.2 29.4 271.2 30.5 270 30.5 268.6 30.5 267.2 29.3 266 27.9 266Z" class="brainPath"/>
<path d="M46 283.5C43.7 283.5 41.8 281.6 41.8 279.3 41.8 277 43.7 275.1 46 275.1 48.3 275.1 50.2 277 50.2 279.3 50.2 281.6 48.3 283.5 46 283.5ZM46 276.6C44.5 276.6 43.4 277.8 43.4 279.2 43.4 280.7 44.6 281.8 46 281.8 47.5 281.8 48.6 280.6 48.6 279.2 48.6 277.8 47.5 276.6 46 276.6Z" class="brainPath"/>
<path d="M93.3 295.5C91 295.5 89.1 293.6 89.1 291.3 89.1 289 91 287.1 93.3 287.1 95.6 287.1 97.5 289 97.5 291.3 97.5 293.6 95.6 295.5 93.3 295.5ZM93.3 288.6C91.8 288.6 90.7 289.8 90.7 291.2 90.7 292.7 91.9 293.8 93.3 293.8 94.8 293.8 95.9 292.6 95.9 291.2 95.9 289.8 94.7 288.6 93.3 288.6Z" class="brainPath"/>
<path d="M110.6 273.1C108.3 273.1 106.4 271.2 106.4 268.9 106.4 266.6 108.3 264.7 110.6 264.7 112.9 264.7 114.8 266.6 114.8 268.9 114.8 271.2 113 273.1 110.6 273.1ZM110.6 266.3C109.1 266.3 108 267.5 108 268.9 108 270.4 109.2 271.5 110.6 271.5 112.1 271.5 113.2 270.3 113.2 268.9 113.3 267.4 112.1 266.3 110.6 266.3Z" class="brainPath"/>
<path d="M110.6 256.7C108.3 256.7 106.4 254.8 106.4 252.5 106.4 250.2 108.3 248.3 110.6 248.3 112.9 248.3 114.8 250.2 114.8 252.5 114.8 254.8 113 256.7 110.6 256.7ZM110.6 249.8C109.1 249.8 108 251 108 252.4 108 253.9 109.2 255 110.6 255 112.1 255 113.2 253.8 113.2 252.4 113.3 251 112.1 249.8 110.6 249.8Z" class="brainPath"/>
<path d="M175 208.5C172.7 208.5 170.8 206.6 170.8 204.3 170.8 202 172.7 200.1 175 200.1 177.3 200.1 179.2 202 179.2 204.3 179.2 206.6 177.4 208.5 175 208.5ZM175 201.6C173.5 201.6 172.4 202.8 172.4 204.2 172.4 205.7 173.6 206.8 175 206.8 176.5 206.8 177.6 205.6 177.6 204.2 177.7 202.8 176.5 201.6 175 201.6Z" class="brainPath"/>
<path d="M147.6 191.8C145.3 191.8 143.4 189.9 143.4 187.6 143.4 185.3 145.3 183.4 147.6 183.4 149.9 183.4 151.8 185.3 151.8 187.6 151.8 189.9 150 191.8 147.6 191.8ZM147.6 185C146.1 185 145 186.2 145 187.6 145 189.1 146.2 190.2 147.6 190.2 149.1 190.2 150.2 189 150.2 187.6 150.3 186.1 149.1 185 147.6 185Z" class="brainPath"/>
<path d="M59.7 216C57.4 216 55.5 214.1 55.5 211.8 55.5 209.5 57.4 207.6 59.7 207.6 62 207.6 63.9 209.5 63.9 211.8 63.9 214.1 62 216 59.7 216ZM59.7 209.2C58.2 209.2 57.1 210.4 57.1 211.8 57.1 213.3 58.3 214.4 59.7 214.4 61.2 214.4 62.3 213.2 62.3 211.8 62.3 210.3 61.2 209.2 59.7 209.2Z" class="brainPath"/>
<path d="M123.4 217.7C121.1 217.7 119.2 215.8 119.2 213.5 119.2 211.2 121.1 209.3 123.4 209.3 125.7 209.3 127.6 211.2 127.6 213.5 127.6 215.8 125.7 217.7 123.4 217.7ZM123.4 210.9C121.9 210.9 120.8 212.1 120.8 213.5 120.8 215 122 216.1 123.4 216.1 124.9 216.1 126 214.9 126 213.5 126.1 212.1 124.9 210.9 123.4 210.9Z" class="brainPath"/>
<path d="M109 157.4C106.7 157.4 104.8 155.5 104.8 153.2 104.8 150.9 106.7 149 109 149 111.3 149 113.2 150.9 113.2 153.2 113.2 155.5 111.4 157.4 109 157.4ZM109 150.6C107.5 150.6 106.4 151.8 106.4 153.2 106.4 154.7 107.6 155.8 109 155.8 110.5 155.8 111.6 154.6 111.6 153.2 111.7 151.8 110.5 150.6 109 150.6Z" class="brainPath"/>
<path d="M200.2 147.8C197.9 147.8 196 145.9 196 143.6 196 141.3 197.9 139.4 200.2 139.4 202.5 139.4 204.4 141.3 204.4 143.6 204.4 146 202.5 147.8 200.2 147.8ZM200.2 141C198.7 141 197.6 142.2 197.6 143.6 197.6 145.1 198.8 146.2 200.2 146.2 201.7 146.2 202.8 145 202.8 143.6 202.8 142.2 201.6 141 200.2 141Z" class="brainPath"/>
<path d="M259.3 101.7C257 101.7 255.1 99.8 255.1 97.5 255.1 95.2 257 93.3 259.3 93.3 261.6 93.3 263.5 95.2 263.5 97.5 263.5 99.8 261.6 101.7 259.3 101.7ZM259.3 94.9C257.8 94.9 256.7 96.1 256.7 97.5 256.7 99 257.9 100.1 259.3 100.1 260.8 100.1 261.9 98.9 261.9 97.5 261.9 96 260.7 94.9 259.3 94.9Z" class="brainPath"/>
<path d="M274.1 172.1C271.9 172.1 270.1 170.4 269.9 168.2 269.7 165.9 271.5 163.9 273.8 163.7 276.1 163.5 278.1 165.3 278.3 167.6L278.3 167.6C278.4 168.7 278 169.8 277.3 170.7 276.6 171.6 275.5 172.1 274.4 172.2 274.4 172 274.2 172.1 274.1 172.1ZM274.1 165.2C274 165.2 274 165.2 273.9 165.2 273.2 165.3 272.6 165.6 272.1 166.1 271.6 166.6 271.4 167.3 271.5 168 271.6 169.4 272.9 170.5 274.3 170.4 275 170.3 275.6 170 276.1 169.5 276.6 169 276.8 168.3 276.7 167.6L276.7 167.6C276.7 166.3 275.5 165.2 274.1 165.2Z" class="brainPath"/>
<path d="M310.2 156.1C309.2 156.1 308.2 155.7 307.5 155.1 306.6 154.4 306.1 153.3 306 152.2 305.9 151.1 306.3 150 307 149.1 307.7 148.2 308.8 147.7 309.9 147.6 311 147.5 312.1 147.9 313 148.6 313.9 149.3 314.4 150.4 314.5 151.5L314.5 151.5C314.6 152.6 314.2 153.7 313.5 154.6 312.8 155.5 311.7 156 310.6 156.1 310.4 156.1 310.3 156.1 310.2 156.1ZM310.2 149.2C310.1 149.2 310.1 149.2 310 149.2 309.3 149.3 308.7 149.6 308.2 150.1 307.7 150.6 307.5 151.3 307.6 152 307.7 152.7 308 153.3 308.5 153.8 309 154.3 309.7 154.5 310.4 154.4 311.1 154.3 311.7 154 312.2 153.5 312.7 153 312.9 152.3 312.8 151.6L312.8 151.6C312.7 150.9 312.4 150.3 311.9 149.8 311.4 149.4 310.8 149.2 310.2 149.2Z" class="brainPath"/>
<path d="M304.6 178.5C302.4 178.5 300.6 176.8 300.4 174.6 300.3 173.5 300.7 172.4 301.4 171.5 302.1 170.6 303.2 170.1 304.3 170 305.4 169.9 306.5 170.3 307.4 171 308.3 171.7 308.8 172.8 308.9 173.9L308.9 173.9C309.1 176.2 307.3 178.2 305 178.4 304.8 178.5 304.7 178.5 304.6 178.5ZM304.6 171.6C304.5 171.6 304.5 171.6 304.4 171.6 303.7 171.7 303.1 172 302.6 172.5 302.1 173 301.9 173.7 302 174.4 302.1 175.1 302.4 175.7 302.9 176.2 303.4 176.7 304.1 176.9 304.8 176.8 306.2 176.7 307.3 175.4 307.2 174L307.2 174C307.1 173.3 306.8 172.7 306.3 172.2 305.8 171.8 305.2 171.6 304.6 171.6Z" class="brainPath"/>
<path d="M354.2 152.3C352 152.3 350.2 150.6 350 148.4 349.9 147.3 350.3 146.2 351 145.3 351.7 144.4 352.8 143.9 353.9 143.8 356.2 143.6 358.2 145.4 358.4 147.7L358.4 147.7C358.6 150 356.8 152 354.5 152.2 354.4 152.3 354.3 152.3 354.2 152.3ZM354.2 145.5C354.1 145.5 354.1 145.5 354 145.5 353.3 145.6 352.7 145.9 352.2 146.4 351.7 146.9 351.5 147.6 351.6 148.3 351.7 149.7 353 150.8 354.4 150.7 355.1 150.6 355.7 150.3 356.2 149.8 356.7 149.3 356.9 148.6 356.8 147.9L356.8 147.9C356.7 146.5 355.6 145.5 354.2 145.5Z" class="brainPath"/>
<path d="M323.7 222.9C321.5 222.9 319.7 221.2 319.5 219 319.3 216.7 321.1 214.7 323.4 214.5 324.5 214.4 325.6 214.8 326.5 215.5 327.4 216.2 327.9 217.3 328 218.4L328 218.4C328.1 219.5 327.7 220.6 327 221.5 326.3 222.4 325.2 222.9 324.1 223 323.9 222.9 323.8 222.9 323.7 222.9ZM323.7 216C323.6 216 323.6 216 323.5 216 322.8 216.1 322.2 216.4 321.7 216.9 321.2 217.4 321 218.1 321.1 218.8 321.2 220.2 322.5 221.3 323.9 221.2 324.6 221.1 325.2 220.8 325.7 220.3 326.2 219.8 326.4 219.1 326.3 218.4L326.3 218.4C326.2 217.7 325.9 217.1 325.4 216.6 324.9 216.2 324.3 216 323.7 216Z" class="brainPath"/>
<path d="M286 193.3C283.3 193.3 281 191.2 280.8 188.5 280.6 185.6 282.7 183.1 285.6 182.9 287 182.8 288.3 183.2 289.4 184.1 290.5 185 291.1 186.3 291.2 187.7L291.2 187.7C291.3 189.1 290.9 190.4 290 191.5 289.1 192.6 287.8 193.2 286.4 193.3 286.2 193.3 286.1 193.3 286 193.3ZM286 184.4C285.9 184.4 285.8 184.4 285.7 184.4 283.7 184.6 282.2 186.3 282.3 188.3 282.5 190.3 284.2 191.8 286.2 191.7 287.2 191.6 288.1 191.2 288.7 190.4 289.3 189.7 289.6 188.7 289.6 187.7L289.6 187.7C289.5 186.7 289.1 185.8 288.3 185.2 287.7 184.7 286.8 184.4 286 184.4Z" class="brainPath"/>
<path d="M174.1 221.5C171.8 221.5 169.9 219.6 169.9 217.3 169.9 215 171.8 213.1 174.1 213.1 176.4 213.1 178.3 215 178.3 217.3 178.3 219.6 176.4 221.5 174.1 221.5ZM174.1 214.6C172.6 214.6 171.5 215.8 171.5 217.2 171.5 218.7 172.7 219.8 174.1 219.8 175.5 219.8 176.7 218.6 176.7 217.2 176.8 215.8 175.6 214.6 174.1 214.6Z" class="brainPath"/>
<path d="M165.9 228.6C163.6 228.6 161.7 226.7 161.7 224.4 161.7 222.1 163.6 220.2 165.9 220.2 168.2 220.2 170.1 222.1 170.1 224.4 170.1 226.7 168.2 228.6 165.9 228.6ZM165.9 221.7C164.4 221.7 163.3 222.9 163.3 224.3 163.3 225.8 164.5 226.9 165.9 226.9 167.4 226.9 168.5 225.7 168.5 224.3 168.5 222.9 167.3 221.7 165.9 221.7Z" class="brainPath"/>
<path d="M259.1 277.9C256.8 277.9 254.9 276 254.9 273.7 254.9 271.4 256.8 269.5 259.1 269.5 261.4 269.5 263.3 271.4 263.3 273.7 263.3 276 261.4 277.9 259.1 277.9ZM259.1 271C257.6 271 256.5 272.2 256.5 273.6 256.5 275.1 257.7 276.2 259.1 276.2 260.6 276.2 261.7 275 261.7 273.6 261.7 272.2 260.5 271 259.1 271Z" class="brainPath"/>
<path d="M391.1 324.9C388.8 324.9 386.9 323 386.9 320.7 386.9 318.4 388.8 316.5 391.1 316.5 393.4 316.5 395.3 318.4 395.3 320.7 395.3 323.1 393.4 324.9 391.1 324.9ZM391.1 318.1C389.6 318.1 388.5 319.3 388.5 320.7 388.5 322.2 389.7 323.3 391.1 323.3 392.6 323.3 393.7 322.1 393.7 320.7 393.7 319.3 392.5 318.1 391.1 318.1Z" class="brainPath"/>
<path d="M259.1 239.1C256.8 239.1 254.9 237.2 254.9 234.9 254.9 232.6 256.8 230.7 259.1 230.7 261.4 230.7 263.3 232.6 263.3 234.9 263.3 237.2 261.4 239.1 259.1 239.1ZM259.1 232.2C257.6 232.2 256.5 233.4 256.5 234.8 256.5 236.3 257.7 237.4 259.1 237.4 260.6 237.4 261.7 236.2 261.7 234.8 261.7 233.4 260.5 232.2 259.1 232.2Z" class="brainPath"/>
<path d="M281.7 204.6C279.4 204.6 277.5 202.7 277.5 200.4 277.5 198.1 279.4 196.2 281.7 196.2 284 196.2 285.9 198.1 285.9 200.4 285.9 202.7 284 204.6 281.7 204.6ZM281.7 197.7C280.2 197.7 279.1 198.9 279.1 200.3 279.1 201.8 280.3 202.9 281.7 202.9 283.2 202.9 284.3 201.7 284.3 200.3 284.3 198.9 283.1 197.7 281.7 197.7Z" class="brainPath"/>
<path d="M246.3 212.8C244 212.8 242.1 210.9 242.1 208.6 242.1 206.3 244 204.4 246.3 204.4 248.6 204.4 250.5 206.3 250.5 208.6 250.5 210.9 248.6 212.8 246.3 212.8ZM246.3 206C244.8 206 243.7 207.2 243.7 208.6 243.7 210.1 244.9 211.2 246.3 211.2 247.8 211.2 248.9 210 248.9 208.6 248.9 207.1 247.7 206 246.3 206Z" class="brainPath"/>
<path d="M145.8 224C143.5 224 141.6 222.1 141.6 219.8 141.6 217.5 143.5 215.6 145.8 215.6 148.1 215.6 150 217.5 150 219.8 150 222.1 148.1 224 145.8 224ZM145.8 217.2C144.3 217.2 143.2 218.4 143.2 219.8 143.2 221.3 144.4 222.4 145.8 222.4 147.3 222.4 148.4 221.2 148.4 219.8 148.4 218.3 147.3 217.2 145.8 217.2Z" class="brainPath"/>
<path d="M149.3 366.1C147 366.1 145.1 364.2 145.1 361.9 145.1 359.6 147 357.7 149.3 357.7 151.6 357.7 153.5 359.6 153.5 361.9 153.5 364.2 151.7 366.1 149.3 366.1ZM149.3 359.3C147.8 359.3 146.7 360.5 146.7 361.9 146.7 363.4 147.9 364.5 149.3 364.5 150.7 364.5 151.9 363.3 151.9 361.9 152 360.4 150.8 359.3 149.3 359.3Z" class="brainPath"/>
<path d="M237.3 360.2C235 360.2 233.1 358.3 233.1 356 233.1 353.7 235 351.8 237.3 351.8 239.6 351.8 241.5 353.7 241.5 356 241.5 358.3 239.6 360.2 237.3 360.2ZM237.3 353.3C235.8 353.3 234.7 354.5 234.7 355.9 234.7 357.4 235.9 358.5 237.3 358.5 238.8 358.5 239.9 357.3 239.9 355.9 239.9 354.5 238.7 353.3 237.3 353.3Z" class="brainPath"/>
<path d="M292.3 348.7C290.8 348.7 289.3 347.9 288.6 346.5 288.1 345.5 288 344.4 288.3 343.3 288.6 342.2 289.4 341.3 290.4 340.8 291.4 340.3 292.5 340.2 293.6 340.5 294.7 340.8 295.6 341.6 296.1 342.6L296.1 342.6C297.2 344.7 296.4 347.2 294.3 348.3 293.6 348.6 293 348.7 292.3 348.7ZM292.3 341.9C291.9 341.9 291.5 342 291.1 342.2 290.5 342.5 290 343.1 289.8 343.8 289.6 344.5 289.7 345.2 290 345.8 290.7 347.1 292.3 347.6 293.6 346.9 294.9 346.2 295.4 344.6 294.7 343.3 294.4 342.7 293.8 342.2 293.1 342 292.8 341.9 292.6 341.9 292.3 341.9Z" class="brainPath"/>
<path d="M301.9 362.8C300.4 362.8 298.9 362 298.2 360.6 297.1 358.5 297.9 356 300 354.9 302.1 353.8 304.6 354.6 305.7 356.7 306.2 357.7 306.3 358.8 306 359.9 305.7 361 304.9 361.9 303.9 362.4 303.2 362.6 302.6 362.8 301.9 362.8ZM301.9 355.9C301.5 355.9 301.1 356 300.7 356.2 299.4 356.9 298.9 358.5 299.6 359.8 300.3 361.1 301.9 361.6 303.2 360.9 303.8 360.6 304.3 360 304.5 359.3 304.7 358.6 304.6 357.9 304.3 357.3 303.7 356.5 302.8 355.9 301.9 355.9Z" class="brainPath"/>
<path d="M248.7 360.2C246.4 360.2 244.5 358.3 244.5 356 244.5 353.7 246.4 351.8 248.7 351.8 251 351.8 252.9 353.7 252.9 356 252.9 358.3 251 360.2 248.7 360.2ZM248.7 353.3C247.2 353.3 246.1 354.5 246.1 355.9 246.1 357.4 247.3 358.5 248.7 358.5 250.2 358.5 251.3 357.3 251.3 355.9 251.3 354.5 250.1 353.3 248.7 353.3Z" class="brainPath"/>
<path d="M273.5 360.2C271.2 360.2 269.3 358.3 269.3 356 269.3 353.7 271.2 351.8 273.5 351.8 275.8 351.8 277.7 353.7 277.7 356 277.7 358.3 275.8 360.2 273.5 360.2ZM273.5 353.3C272 353.3 270.9 354.5 270.9 355.9 270.9 357.4 272.1 358.5 273.5 358.5 275 358.5 276.1 357.3 276.1 355.9 276.1 354.5 274.9 353.3 273.5 353.3Z" class="brainPath"/>
<path d="M261.9 399.7C259.6 399.7 257.7 397.8 257.7 395.5 257.7 393.2 259.6 391.3 261.9 391.3 264.2 391.3 266.1 393.2 266.1 395.5 266.1 397.8 264.2 399.7 261.9 399.7ZM261.9 392.8C260.4 392.8 259.3 394 259.3 395.4 259.3 396.9 260.5 398 261.9 398 263.4 398 264.5 396.8 264.5 395.4 264.6 394 263.4 392.8 261.9 392.8Z" class="brainPath"/>
<path d="M273.6 399.7C271.3 399.7 269.4 397.8 269.4 395.5 269.4 393.2 271.3 391.3 273.6 391.3 275.9 391.3 277.8 393.2 277.8 395.5 277.8 397.8 275.9 399.7 273.6 399.7ZM273.6 392.8C272.1 392.8 271 394 271 395.4 271 396.9 272.2 398 273.6 398 275.1 398 276.2 396.8 276.2 395.4 276.2 394 275 392.8 273.6 392.8Z" class="brainPath"/>
<path d="M20.7 124.9C18.4 124.9 16.5 123 16.5 120.7 16.5 118.4 18.4 116.5 20.7 116.5 23 116.5 24.9 118.4 24.9 120.7 24.9 123 23 124.9 20.7 124.9ZM20.7 118C19.2 118 18.1 119.2 18.1 120.6 18.1 122.1 19.3 123.2 20.7 123.2 22.2 123.2 23.3 122 23.3 120.6 23.3 119.2 22.1 118 20.7 118Z" class="brainPath"/>
<path d="M62.9 114.6C60.6 114.6 58.7 112.7 58.7 110.4 58.7 108.1 60.6 106.2 62.9 106.2 65.2 106.2 67.1 108.1 67.1 110.4 67.1 112.7 65.2 114.6 62.9 114.6ZM62.9 107.8C61.4 107.8 60.3 109 60.3 110.4 60.3 111.9 61.5 113 62.9 113 64.4 113 65.5 111.8 65.5 110.4 65.5 109 64.4 107.8 62.9 107.8Z" class="brainPath"/>
<path d="M73.9 93.8C71.6 93.8 69.7 91.9 69.7 89.6 69.7 87.3 71.6 85.4 73.9 85.4 76.2 85.4 78.1 87.3 78.1 89.6 78.1 91.9 76.2 93.8 73.9 93.8ZM73.9 87C72.4 87 71.3 88.2 71.3 89.6 71.3 91.1 72.5 92.2 73.9 92.2 75.4 92.2 76.5 91 76.5 89.6 76.5 88.2 75.3 87 73.9 87Z" class="brainPath"/>
<path d="M60.4 71.2C58.1 71.2 56.2 69.3 56.2 67 56.2 64.7 58.1 62.8 60.4 62.8 62.7 62.8 64.6 64.7 64.6 67 64.6 69.3 62.7 71.2 60.4 71.2ZM60.4 64.4C58.9 64.4 57.8 65.6 57.8 67 57.8 68.5 59 69.6 60.4 69.6 61.9 69.6 63 68.4 63 67 63 65.6 61.8 64.4 60.4 64.4Z" class="brainPath"/>
<path d="M95.8 45.7C93.5 45.7 91.6 43.8 91.6 41.5 91.6 39.2 93.5 37.3 95.8 37.3 98.1 37.3 100 39.2 100 41.5 100 43.8 98.1 45.7 95.8 45.7ZM95.8 38.8C94.3 38.8 93.2 40 93.2 41.4 93.2 42.9 94.4 44 95.8 44 97.3 44 98.4 42.8 98.4 41.4 98.4 40 97.2 38.8 95.8 38.8Z" class="brainPath"/>
<path d="M47.4 87.7C45.1 87.7 43.2 85.8 43.2 83.5 43.2 81.2 45.1 79.3 47.4 79.3 49.7 79.3 51.6 81.2 51.6 83.5 51.6 85.8 49.7 87.7 47.4 87.7ZM47.4 80.8C45.9 80.8 44.8 82 44.8 83.4 44.8 84.9 46 86 47.4 86 48.9 86 50 84.8 50 83.4 50 82 48.8 80.8 47.4 80.8Z" class="brainPath"/>
<path d="M39.8 198.9C37.5 198.9 35.6 197 35.6 194.7 35.6 192.4 37.5 190.5 39.8 190.5 42.1 190.5 44 192.4 44 194.7 44.1 197 42.2 198.9 39.8 198.9ZM39.8 192C38.3 192 37.2 193.2 37.2 194.6 37.2 196.1 38.4 197.2 39.8 197.2 41.3 197.2 42.4 196 42.4 194.6 42.5 193.2 41.3 192 39.8 192Z" class="brainPath"/>
<path d="M84.4 100C82.1 100 80.2 98.1 80.2 95.8 80.2 93.5 82.1 91.6 84.4 91.6 86.7 91.6 88.6 93.5 88.6 95.8 88.6 98.1 86.7 100 84.4 100ZM84.4 93.2C82.9 93.2 81.8 94.4 81.8 95.8 81.8 97.2 83 98.4 84.4 98.4 85.9 98.4 87 97.2 87 95.8 87 94.4 85.8 93.2 84.4 93.2Z" class="brainPath"/>
<path d="M94.6 92.9C92.3 92.9 90.4 91 90.4 88.7 90.4 86.4 92.3 84.5 94.6 84.5 96.9 84.5 98.8 86.4 98.8 88.7 98.9 91 97 92.9 94.6 92.9ZM94.6 86.1C93.1 86.1 92 87.3 92 88.7 92 90.2 93.2 91.3 94.6 91.3 96.1 91.3 97.2 90.1 97.2 88.7 97.3 87.3 96.1 86.1 94.6 86.1Z" class="brainPath"/>
<path d="M123.4 37.4C121.1 37.4 119.2 35.5 119.2 33.2 119.2 30.9 121.1 29 123.4 29 125.7 29 127.6 30.9 127.6 33.2 127.6 35.5 125.7 37.4 123.4 37.4ZM123.4 30.6C121.9 30.6 120.8 31.8 120.8 33.2 120.8 34.7 122 35.8 123.4 35.8 124.9 35.8 126 34.6 126 33.2 126.1 31.8 124.9 30.6 123.4 30.6Z" class="brainPath"/>
<path d="M226.9 13C224.6 13 222.7 11.1 222.7 8.8 222.7 6.5 224.6 4.6 226.9 4.6 229.2 4.6 231.1 6.5 231.1 8.8 231.1 11.1 229.2 13 226.9 13ZM226.9 6.1C225.4 6.1 224.3 7.3 224.3 8.7 224.3 10.1 225.5 11.3 226.9 11.3 228.4 11.3 229.5 10.1 229.5 8.7 229.5 7.3 228.3 6.1 226.9 6.1Z" class="brainPath"/>
<path d="M250.4 26C248.1 26 246.2 24.1 246.2 21.8 246.2 19.5 248.1 17.6 250.4 17.6 252.7 17.6 254.6 19.5 254.6 21.8 254.6 24.1 252.7 26 250.4 26ZM250.4 19.2C248.9 19.2 247.8 20.4 247.8 21.8 247.8 23.3 249 24.4 250.4 24.4 251.9 24.4 253 23.2 253 21.8 253 20.3 251.8 19.2 250.4 19.2Z" class="brainPath"/>
<path d="M260.4 8.4C258.1 8.4 256.2 6.5 256.2 4.2 256.2 1.9 258.1 0 260.4 0 262.7 0 264.6 1.9 264.6 4.2 264.7 6.5 262.8 8.4 260.4 8.4ZM260.4 1.6C258.9 1.6 257.8 2.8 257.8 4.2 257.8 5.7 259 6.8 260.4 6.8 261.9 6.8 263 5.6 263 4.2 263.1 2.8 261.9 1.6 260.4 1.6Z" class="brainPath"/>
<path d="M270.9 13.7C267.9 13.7 265.4 11.2 265.4 8.2 265.4 5.2 267.9 2.7 270.9 2.7 273.9 2.7 276.4 5.2 276.4 8.2 276.5 11.2 274 13.7 270.9 13.7ZM270.9 4.2C268.7 4.2 267 6 267 8.1 267 10.3 268.8 12 270.9 12 273.1 12 274.8 10.2 274.8 8.1 274.9 6 273.1 4.2 270.9 4.2Z" class="brainPath"/>
<path d="M38.5 214C36.2 214 34.3 212.1 34.3 209.8 34.3 207.5 36.2 205.6 38.5 205.6 40.8 205.6 42.7 207.5 42.7 209.8 42.7 212.1 40.8 214 38.5 214ZM38.5 207.1C37 207.1 35.9 208.3 35.9 209.7 35.9 211.2 37.1 212.3 38.5 212.3 40 212.3 41.1 211.1 41.1 209.7 41.1 208.3 39.9 207.1 38.5 207.1Z" class="brainPath"/>
<path d="M53.8 294 22.1 275.4 9.7 254.7 11.1 253.9 23.3 274.2 54.6 292.6Z" class="brainPath"/>
<path d="M203.3 153.7C203.3 155.4 201.9 156.8 200.2 156.8 198.5 156.8 197.1 155.4 197.1 153.7 197.1 152 198.5 150.6 200.2 150.6 202 150.6 203.3 152 203.3 153.7Z" class="brainPath"/>
<path d="M154.6 22.7C154.6 24.4 153.2 25.8 151.5 25.8 149.8 25.8 148.4 24.4 148.4 22.7 148.4 21 149.8 19.6 151.5 19.6 153.2 19.6 154.6 21 154.6 22.7Z" class="brainPath"/>
<path d="M186.4 18.6C186.4 20.3 185 21.7 183.3 21.7 181.6 21.7 180.2 20.3 180.2 18.6 180.2 16.9 181.6 15.5 183.3 15.5 185.1 15.5 186.4 16.9 186.4 18.6Z" class="brainPath"/>
<path d="M278.5 80.5C278.5 82.2 277.1 83.6 275.4 83.6 273.7 83.6 272.3 82.2 272.3 80.5 272.3 78.8 273.7 77.4 275.4 77.4 277.1 77.4 278.5 78.8 278.5 80.5Z" class="brainPath"/>
<path d="M331 154.5C331 156.2 329.6 157.6 327.9 157.6 326.2 157.6 324.8 156.2 324.8 154.5 324.8 152.8 326.2 151.4 327.9 151.4 329.6 151.4 331 152.8 331 154.5Z" class="brainPath"/>
<path d="M300.2 197.6C300.2 199.3 298.8 200.7 297.1 200.7 295.4 200.7 294 199.3 294 197.6 294 195.9 295.4 194.5 297.1 194.5 298.8 194.6 300.2 195.9 300.2 197.6Z" class="brainPath"/>
<path d="M277.5 152.1C277.5 153.8 276.1 155.2 274.4 155.2 272.7 155.2 271.3 153.8 271.3 152.1 271.3 150.4 272.7 149 274.4 149 276.1 149 277.5 150.4 277.5 152.1Z" class="brainPath"/>
<path d="M281.4 118.5C281.4 120.2 280 121.6 278.3 121.6 276.6 121.6 275.2 120.2 275.2 118.5 275.2 116.8 276.6 115.4 278.3 115.4 280.1 115.4 281.4 116.8 281.4 118.5Z" class="brainPath"/>
<path d="M323.6 159C323.6 160.7 322.2 162.1 320.5 162.1 318.8 162.1 317.4 160.7 317.4 159 317.4 157.3 318.8 155.9 320.5 155.9 322.2 156 323.6 157.3 323.6 159Z" class="brainPath"/>
<path d="M288.7 153.9C288.7 156.1 286.9 157.9 284.7 157.9 282.5 157.9 280.7 156.1 280.7 153.9 280.7 151.7 282.5 149.9 284.7 149.9 286.9 149.9 288.7 151.7 288.7 153.9Z" class="brainPath"/>
<path d="M288.7 173.5C288.7 175.7 286.9 177.5 284.7 177.5 282.5 177.5 280.7 175.7 280.7 173.5 280.7 171.3 282.5 169.5 284.7 169.5 286.9 169.5 288.7 171.3 288.7 173.5Z" class="brainPath"/>
<path d="M298.7 176.3C298.7 178.5 296.9 180.3 294.7 180.3 292.5 180.3 290.7 178.5 290.7 176.3 290.7 174.1 292.5 172.3 294.7 172.3 296.9 172.2 298.7 174 298.7 176.3Z" class="brainPath"/>
<path d="M395.7 121.7C395.7 123.4 394.3 124.8 392.6 124.8 390.9 124.8 389.5 123.4 389.5 121.7 389.5 120 390.9 118.6 392.6 118.6 394.4 118.6 395.7 120 395.7 121.7Z" class="brainPath"/>
<path d="M433.1 47.7C433.1 49.4 431.7 50.8 430 50.8 428.3 50.8 426.9 49.4 426.9 47.7 426.9 46 428.3 44.6 430 44.6 431.7 44.6 433.1 46 433.1 47.7Z" class="brainPath"/>
<path d="M500.4 157.4C500.4 159.1 499 160.5 497.3 160.5 495.6 160.5 494.2 159.1 494.2 157.4 494.2 155.7 495.6 154.3 497.3 154.3 499.1 154.3 500.4 155.7 500.4 157.4Z" class="brainPath"/>
<path d="M500.4 169.4C500.4 171.1 499 172.5 497.3 172.5 495.6 172.5 494.2 171.1 494.2 169.4 494.2 167.7 495.6 166.3 497.3 166.3 499.1 166.3 500.4 167.7 500.4 169.4Z" class="brainPath"/>
<path d="M496.1 197.8C496.1 199.5 494.7 200.9 493 200.9 491.3 200.9 489.9 199.5 489.9 197.8 489.9 196.1 491.3 194.7 493 194.7 494.7 194.7 496.1 196.1 496.1 197.8Z" class="brainPath"/>
<path d="M458.7 227.9C458.7 229.6 457.3 231 455.6 231 453.9 231 452.5 229.6 452.5 227.9 452.5 226.2 453.9 224.8 455.6 224.8 457.3 224.8 458.7 226.2 458.7 227.9Z" class="brainPath"/>
<path d="M419.9 131.6C419.9 133.3 418.5 134.7 416.8 134.7 415.1 134.7 413.7 133.3 413.7 131.6 413.7 129.9 415.1 128.5 416.8 128.5 418.5 128.6 419.9 129.9 419.9 131.6Z" class="brainPath"/>
<path d="M353.6 247.4C353.6 249.1 352.2 250.5 350.5 250.5 348.8 250.5 347.4 249.1 347.4 247.4 347.4 245.7 348.8 244.3 350.5 244.3 352.2 244.3 353.6 245.7 353.6 247.4Z" class="brainPath"/>
<path d="M341.2 272.4C341.2 274.1 339.8 275.5 338.1 275.5 336.4 275.5 335 274.1 335 272.4 335 270.7 336.4 269.3 338.1 269.3 339.8 269.3 341.2 270.7 341.2 272.4Z" class="brainPath"/>
<path d="M395.1 169C395.1 170.7 393.7 172.1 392 172.1 390.3 172.1 388.9 170.7 388.9 169 388.9 167.3 390.3 165.9 392 165.9 393.7 165.9 395.1 167.3 395.1 169Z" class="brainPath"/>
<path d="M381.4 245.3C381.4 247 380 248.4 378.3 248.4 376.6 248.4 375.2 247 375.2 245.3 375.2 243.6 376.6 242.2 378.3 242.2 380 242.2 381.4 243.5 381.4 245.3Z" class="brainPath"/>
<path d="M412.2 188.2C412.2 189.9 410.8 191.3 409.1 191.3 407.4 191.3 406 189.9 406 188.2 406 186.5 407.4 185.1 409.1 185.1 410.8 185.1 412.2 186.5 412.2 188.2Z" class="brainPath"/>
<path d="M428.9 248.4C428.9 250.1 427.5 251.5 425.8 251.5 424.1 251.5 422.7 250.1 422.7 248.4 422.7 246.7 424.1 245.3 425.8 245.3 427.5 245.4 428.9 246.7 428.9 248.4Z" class="brainPath"/>
<path d="M479.6 312.2C479.6 313.9 478.2 315.3 476.5 315.3 474.8 315.3 473.4 313.9 473.4 312.2 473.4 310.5 474.8 309.1 476.5 309.1 478.2 309.1 479.6 310.5 479.6 312.2Z" class="brainPath"/>
<path d="M533.5 269.3C533.5 271 532.1 272.4 530.4 272.4 528.7 272.4 527.3 271 527.3 269.3 527.3 267.6 528.7 266.2 530.4 266.2 532.1 266.3 533.5 267.6 533.5 269.3Z" class="brainPath"/>
<path d="M533.5 276.7C533.5 278.4 532.1 279.8 530.4 279.8 528.7 279.8 527.3 278.4 527.3 276.7 527.3 275 528.7 273.6 530.4 273.6 532.1 273.6 533.5 275 533.5 276.7Z" class="brainPath"/>
<path d="M575.4 269.3C575.4 271 574 272.4 572.3 272.4 570.6 272.4 569.2 271 569.2 269.3 569.2 267.6 570.6 266.2 572.3 266.2 574 266.3 575.4 267.6 575.4 269.3Z" class="brainPath"/>
<path d="M577.3 298.3C577.3 300 575.9 301.4 574.2 301.4 572.5 301.4 571.1 300 571.1 298.3 571.1 296.6 572.5 295.2 574.2 295.2 575.9 295.2 577.3 296.6 577.3 298.3Z" class="brainPath"/>
<path d="M566.8 324.2C566.8 325.9 565.4 327.3 563.7 327.3 562 327.3 560.6 325.9 560.6 324.2 560.6 322.5 562 321.1 563.7 321.1 565.5 321.1 566.8 322.4 566.8 324.2Z" class="brainPath"/>
<path d="M561.9 336.5C561.9 338.2 560.5 339.6 558.8 339.6 557.1 339.6 555.7 338.2 555.7 336.5 555.7 334.8 557.1 333.4 558.8 333.4 560.5 333.4 561.9 334.8 561.9 336.5Z" class="brainPath"/>
<path d="M486.9 400.5C486.9 402.2 485.5 403.6 483.8 403.6 482.1 403.6 480.7 402.2 480.7 400.5 480.7 398.8 482.1 397.4 483.8 397.4 485.5 397.4 486.9 398.8 486.9 400.5Z" class="brainPath"/>
<path d="M439.1 426.7C439.1 428.4 437.7 429.8 436 429.8 434.3 429.8 432.9 428.4 432.9 426.7 432.9 425 434.3 423.6 436 423.6 437.8 423.6 439.1 425 439.1 426.7Z" class="brainPath"/>
<path d="M404.8 453.9C404.8 455.6 403.4 457 401.7 457 400 457 398.6 455.6 398.6 453.9 398.6 452.2 400 450.8 401.7 450.8 403.4 450.8 404.8 452.2 404.8 453.9Z" class="brainPath"/>
<path d="M395.2 461.1C395.2 462.8 393.8 464.2 392.1 464.2 390.4 464.2 389 462.8 389 461.1 389 459.4 390.4 458 392.1 458 393.8 458 395.2 459.4 395.2 461.1Z" class="brainPath"/>
<path d="M359.2 467.9C359.2 469.6 357.8 471 356.1 471 354.4 471 353 469.6 353 467.9 353 466.2 354.4 464.8 356.1 464.8 357.8 464.8 359.2 466.2 359.2 467.9Z" class="brainPath"/>
<path d="M286.9 390.1C286.9 391.8 285.5 393.2 283.8 393.2 282.1 393.2 280.7 391.8 280.7 390.1 280.7 388.4 282.1 387 283.8 387 285.5 387.1 286.9 388.4 286.9 390.1Z" class="brainPath"/>
<path d="M396.4 503.6C396.4 505.3 395 506.7 393.3 506.7 391.6 506.7 390.2 505.3 390.2 503.6 390.2 501.9 391.6 500.5 393.3 500.5 395 500.6 396.4 501.9 396.4 503.6Z" class="brainPath"/>
<path d="M361.5 399.5C361.5 401.2 360.1 402.6 358.4 402.6 356.7 402.6 355.3 401.2 355.3 399.5 355.3 397.8 356.7 396.4 358.4 396.4 360.1 396.4 361.5 397.8 361.5 399.5Z" class="brainPath"/>
<path d="M382.5 312.2C382.5 313.9 381.1 315.3 379.4 315.3 377.7 315.3 376.3 313.9 376.3 312.2 376.3 310.5 377.7 309.1 379.4 309.1 381.1 309.1 382.5 310.5 382.5 312.2Z" class="brainPath"/>
<path d="M427.3 327.7C427.3 329.4 425.9 330.8 424.2 330.8 422.5 330.8 421.1 329.4 421.1 327.7 421.1 326 422.5 324.6 424.2 324.6 425.9 324.6 427.3 326 427.3 327.7Z" class="brainPath"/>
<path d="M377.7 339.3C377.7 341 376.3 342.4 374.6 342.4 372.9 342.4 371.5 341 371.5 339.3 371.5 337.6 372.9 336.2 374.6 336.2 376.3 336.3 377.7 337.6 377.7 339.3Z" class="brainPath"/>
<path d="M361 309.7C361 311.4 359.6 312.8 357.9 312.8 356.2 312.8 354.8 311.4 354.8 309.7 354.8 308 356.2 306.6 357.9 306.6 359.7 306.6 361 307.9 361 309.7Z" class="brainPath"/>
<path d="M448.3 219.4C448.3 221.1 446.9 222.5 445.2 222.5 443.5 222.5 442.1 221.1 442.1 219.4 442.1 217.7 443.5 216.3 445.2 216.3 446.9 216.4 448.3 217.7 448.3 219.4Z" class="brainPath"/>
<path d="M247.2 96.2C247.2 97.9 245.8 99.3 244.1 99.3 242.4 99.3 241 97.9 241 96.2 241 94.5 242.4 93.1 244.1 93.1 245.8 93.2 247.2 94.5 247.2 96.2Z" class="brainPath"/>
<path d="M385.8 25.8C385.8 27.5 384.4 28.9 382.7 28.9 381 28.9 379.6 27.5 379.6 25.8 379.6 24.1 381 22.7 382.7 22.7 384.4 22.7 385.8 24.1 385.8 25.8Z" class="brainPath"/>
<path d="M72.7 298.8C72.7 300.5 71.3 301.9 69.6 301.9 67.9 301.9 66.5 300.5 66.5 298.8 66.5 297.1 67.9 295.7 69.6 295.7 71.3 295.7 72.7 297.1 72.7 298.8Z" class="brainPath"/>
<path d="M42.2 269.3C42.2 271 40.8 272.4 39.1 272.4 37.4 272.4 36 271 36 269.3 36 267.6 37.4 266.2 39.1 266.2 40.9 266.3 42.2 267.6 42.2 269.3Z" class="brainPath"/>
<path d="M172.1 253.2C172.1 254.9 170.7 256.3 169 256.3 167.3 256.3 165.9 254.9 165.9 253.2 165.9 251.5 167.3 250.1 169 250.1 170.7 250.2 172.1 251.5 172.1 253.2Z" class="brainPath"/>
<path d="M150.4 177.7C150.4 179.4 149 180.8 147.3 180.8 145.6 180.8 144.2 179.4 144.2 177.7 144.2 176 145.6 174.6 147.3 174.6 149 174.6 150.4 176 150.4 177.7Z" class="brainPath"/>
<path d="M65.2 184.8C66.7 185.7 67.2 187.5 66.3 189 65.4 190.5 63.6 191 62.1 190.1 60.6 189.2 60.1 187.4 61 185.9 61.9 184.5 63.8 184 65.2 184.8Z" class="brainPath"/>
<path d="M97.6 213.5C97.6 215.2 96.2 216.6 94.5 216.6 92.8 216.6 91.4 215.2 91.4 213.5 91.4 211.8 92.8 210.4 94.5 210.4 96.2 210.4 97.6 211.8 97.6 213.5Z" class="brainPath"/>
<path d="M177.2 231.4C177.7 233 176.7 234.7 175.1 235.2 173.5 235.7 171.8 234.7 171.3 233.1 170.8 231.5 171.8 229.8 173.4 229.3 175 228.8 176.7 229.8 177.2 231.4Z" class="brainPath"/>
<path d="M168.8 232.8C169.3 234.4 168.3 236.1 166.7 236.6 165.1 237.1 163.4 236.1 162.9 234.5 162.4 232.9 163.4 231.2 165 230.7 166.6 230.2 168.3 231.1 168.8 232.8Z" class="brainPath"/>
<path d="M168.8 201.5C169.3 203.1 168.3 204.8 166.7 205.3 165.1 205.8 163.4 204.8 162.9 203.2 162.4 201.6 163.4 199.9 165 199.4 166.6 198.9 168.3 199.9 168.8 201.5Z" class="brainPath"/>
<path d="M177.2 241.7C177.7 243.3 176.7 245 175.1 245.5 173.5 246 171.8 245 171.3 243.4 170.8 241.8 171.8 240.1 173.4 239.6 175 239.1 176.7 240 177.2 241.7Z" class="brainPath"/>
<path d="M108.8 306.9C108.8 310.1 106.2 312.6 103.1 312.6 100 312.6 97.4 310 97.4 306.9 97.4 303.7 100 301.2 103.1 301.2 106.2 301.2 108.8 303.8 108.8 306.9Z" class="brainPath"/>
<path d="M117.5 283.8C117.5 285.5 116.1 286.9 114.4 286.9 112.7 286.9 111.3 285.5 111.3 283.8 111.3 282.1 112.7 280.7 114.4 280.7 116.1 280.7 117.5 282.1 117.5 283.8Z" class="brainPath"/>
<path d="M96.5 301.9C96.5 303.6 95.1 305 93.4 305 91.7 305 90.3 303.6 90.3 301.9 90.3 300.2 91.7 298.8 93.4 298.8 95.1 298.8 96.5 300.2 96.5 301.9Z" class="brainPath"/>
<path d="M189.1 295.7C189.8 297.3 189 299.1 187.5 299.7 185.9 300.4 184.1 299.6 183.5 298.1 182.8 296.5 183.6 294.7 185.1 294.1 186.6 293.5 188.5 294.1 189.1 295.7Z" class="brainPath"/>
<path d="M231.7 315.9C232.2 317.5 231.2 319.2 229.6 319.7 228 320.2 226.3 319.2 225.8 317.6 225.3 316 226.3 314.3 227.9 313.8 229.6 313.3 231.3 314.3 231.7 315.9Z" class="brainPath"/>
<path d="M276.8 295.7C277.3 297.3 276.3 299 274.7 299.5 273.1 300 271.4 299 270.9 297.4 270.4 295.8 271.4 294.1 273 293.6 274.6 293.1 276.3 294 276.8 295.7Z" class="brainPath"/>
<path d="M311.4 310.8C311.9 312.4 310.9 314.1 309.3 314.6 307.7 315.1 306 314.1 305.5 312.5 305 310.9 306 309.2 307.6 308.7 309.2 308.2 310.9 309.1 311.4 310.8Z" class="brainPath"/>
<path d="M316.3 323.8C316.8 325.4 315.8 327.1 314.2 327.6 312.6 328.1 310.9 327.1 310.4 325.5 309.9 323.9 310.9 322.2 312.5 321.7 314.2 321.2 315.9 322.1 316.3 323.8Z" class="brainPath"/>
<path d="M267.3 282.3C268.7 283.3 269 285.2 268 286.6 267 288 265.1 288.3 263.7 287.3 262.3 286.3 262 284.4 263 283 264 281.6 266 281.3 267.3 282.3Z" class="brainPath"/>
<path d="M271.2 267.5C272.6 268.5 272.9 270.4 271.9 271.8 270.9 273.2 269 273.5 267.6 272.5 266.2 271.5 265.9 269.6 266.9 268.2 267.9 266.8 269.8 266.5 271.2 267.5Z" class="brainPath"/>
<path d="M240.4 197.4C241.8 198.4 242.1 200.3 241.1 201.7 240.1 203.1 238.2 203.4 236.8 202.4 235.4 201.4 235.1 199.5 236.1 198.1 237.1 196.7 239 196.4 240.4 197.4Z" class="brainPath"/>
<path d="M238.8 184.8C240.2 185.8 240.5 187.7 239.5 189.1 238.5 190.5 236.6 190.8 235.2 189.8 233.8 188.8 233.5 186.9 234.5 185.5 235.5 184.1 237.4 183.8 238.8 184.8Z" class="brainPath"/>
<path d="M240.4 149.9C241.8 150.9 242.1 152.8 241.1 154.2 240.1 155.6 238.2 155.9 236.8 154.9 235.4 153.9 235.1 152 236.1 150.6 237.1 149.2 239 148.9 240.4 149.9Z" class="brainPath"/>
<path d="M246.4 220C247.6 218.3 250 217.9 251.7 219.2 253.4 220.4 253.8 222.8 252.5 224.5 251.3 226.2 248.9 226.6 247.2 225.3 245.6 224 245.2 221.7 246.4 220Z" class="brainPath"/>
<path d="M264.5 357.3C264.5 359 263.1 360.4 261.4 360.4 259.7 360.4 258.3 359 258.3 357.3 258.3 355.6 259.7 354.2 261.4 354.2 263.1 354.2 264.5 355.6 264.5 357.3Z" class="brainPath"/>
<path d="M222.5 394.8C222.5 396.5 221.1 397.9 219.4 397.9 217.7 397.9 216.3 396.5 216.3 394.8 216.3 393.1 217.7 391.7 219.4 391.7 221.1 391.7 222.5 393.1 222.5 394.8Z" class="brainPath"/>
<path d="M44.6 245.7C44.6 248.5 42.3 250.8 39.5 250.8 36.7 250.8 34.4 248.5 34.4 245.7 34.4 242.9 36.7 240.6 39.5 240.6 42.3 240.6 44.6 242.9 44.6 245.7Z" class="brainPath"/>
<path d="M287.2 306.7C287.2 309.5 284.9 311.8 282.1 311.8 279.3 311.8 277 309.5 277 306.7 277 303.9 279.3 301.6 282.1 301.6 284.9 301.5 287.2 303.8 287.2 306.7Z" class="brainPath"/>
<path d="M293.3 361.2C293.3 364 291 366.3 288.2 366.3 285.4 366.3 283.1 364 283.1 361.2 283.1 358.4 285.4 356.1 288.2 356.1 291 356.1 293.3 358.4 293.3 361.2Z" class="brainPath"/>
<path d="M127.9 120.3C127.9 123.1 125.6 125.4 122.8 125.4 120 125.4 117.7 123.1 117.7 120.3 117.7 117.5 120 115.2 122.8 115.2 125.6 115.2 127.9 117.5 127.9 120.3Z" class="brainPath"/>
<path d="M167.5 99.7C171.2 99.7 174.2 102.7 174.2 106.4 174.2 110.1 171.2 113.1 167.5 113.1 163.8 113.1 160.8 110.1 160.8 106.4 160.8 102.7 163.8 99.7 167.5 99.7Z" class="brainPath"/>
<path d="M403 60.1C407 60.1 410.3 63.4 410.3 67.4 410.3 71.4 407 74.7 403 74.7 399 74.7 395.7 71.4 395.7 67.4 395.7 63.4 399 60.1 403 60.1Z" class="brainPath"/>
<path d="M434.9 205.5C438.3 205.5 441.1 208.3 441.1 211.7 441.1 215.1 438.3 217.9 434.9 217.9 431.5 217.9 428.7 215.1 428.7 211.7 428.7 208.3 431.5 205.5 434.9 205.5Z" class="brainPath"/>
<path d="M348.8 17.8C352.8 17.8 356.1 21.1 356.1 25.1 356.1 29.1 352.8 32.4 348.8 32.4 344.8 32.4 341.5 29.1 341.5 25.1 341.5 21.1 344.7 17.8 348.8 17.8Z" class="brainPath"/>
<rect x="17.8" y="172.7" width="1.6" height="69.9" class="brainRect"/>
<path d="M29 265.9 27.4 265.9 27.4 142 21.3 123.7 22.8 123.2 29 141.8Z" class="brainPath"/>
<path d="M40.6 191.9 39 191.9 39 133.8 27.9 104.7 41.4 94 42.4 95.3 29.8 105.2 40.6 133.6Z" class="brainPath"/>
<path d="M167.3 253.7 138.3 253.7 104 231.8 37.7 231.8 37.7 213.2 39.3 213.2 39.3 230.2 104.5 230.2 138.7 252.1 167.3 252.1Z" class="brainPath"/>
<path d="M103.7 303.6 102.1 303.6 102.1 246.8 38.5 246.8 38.5 245.3 103.7 245.3Z" class="brainPath"/>
<path d="M150.2 358.4 148.7 358.4 148.7 285 181.5 285 181.5 268.8 135.5 268.8 112.7 254.3 113.6 252.9 136 267.2 183.1 267.2 183.1 286.6 150.2 286.6Z" class="brainPath"/>
<path d="M357.5 399.7 298.4 329.7 216.1 331.8 198 284.6 198 184.1 199.6 184.1 199.6 284.3 217.1 330.2 299.2 328.1 358.7 398.7Z" class="brainPath"/>
<path d="M205.1 346.9 185.7 298.4 187.2 297.8 206.2 345.3 289.2 343.9 289.2 345.5Z" class="brainPath"/>
<path d="M475 405 473.4 405 473.4 338.4 506.3 338.4 506.3 257.8 473 257.8 473 256.2 507.9 256.2 507.9 340 475 340Z" class="brainPath"/>
<path d="M45.1 111 44.2 109.8 55.3 101.3 48.4 87.3 49.8 86.6 57.3 101.7Z" class="brainPath"/>
<path d="M50.2 218 48.6 218 50 120.3 59.9 111.9 60.9 113.1 51.6 121Z" class="brainPath"/>
<path d="M62.9 210.4 62 209.1 78.8 198.3 78.8 133.3 79.1 133.1 150.7 77.8 150.7 22.7 152.3 22.7 152.3 78.6 152 78.8 80.4 134.1 80.4 199.2Z" class="brainPath"/>
<path d="M95.4 213.2 93.9 213.2 93.9 140.8 121.6 120.4 122.5 121.7 95.4 141.6Z" class="brainPath"/>
<path d="M64.6 187.1 63 187.1 63.3 128.1 113.4 89.9 114.3 91.2 64.8 128.8Z" class="brainPath"/>
<path d="M145.8 104.2 144.9 102.9 167.2 85.4 167.2 27.2 168.7 27.2 168.7 86.1Z" class="brainPath"/>
<path d="M227.5 316.9 213.1 279.1 213.1 130.6 214.6 130.6 214.6 278.8 229 316.3Z" class="brainPath"/>
<path d="M464.6 413.3 388.9 413.3 388.6 413 312.6 325.4 313.8 324.3 389.6 411.7 464.6 411.7Z" class="brainPath"/>
<path d="M187.1 387 163.7 374.6 163.7 298.7 175.5 298.7 175.5 300.3 165.3 300.3 165.3 373.7 187.8 385.6Z" class="brainPath"/>
<path d="M218 362.6 196.2 362.6 176.8 314.2 178.3 313.6 197.3 361 218 361Z" class="brainPath"/>
<path d="M221.1 394.7 220.3 393.3 236.4 384 236.4 359.1 237.9 359.1 237.9 384.9Z" class="brainPath"/>
<rect x="248.3" y="359.1" width="1.6" height="32.9" class="brainRect" transform="matrix(0.9998 -0.0208261 0.0208261 0.9998 -7.7668 5.2699)"/>
<rect x="260.7" y="359.1" width="1.6" height="32.9" class="brainRect" transform="matrix(0.9998 -0.0208261 0.0208261 0.9998 -7.7642 5.5268)"/>
<rect x="272.7" y="359.8" width="1.6" height="32.9" class="brainRect"/>
<rect x="342.9" y="346.2" width="1.6" height="120.7" class="brainRect" transform="matrix(0.7577 -0.6527 0.6527 0.7577 -182.038 322.825)"/>
<path d="M392.6 504.4 389.6 480.3 287.8 362.7 289 361.7 391.1 479.6 394.1 504.2Z" class="brainPath"/>
<path d="M356.8 468.1 355.2 467.9 356.9 458 283.8 376.1 285 375 358.6 457.5Z" class="brainPath"/>
<path d="M342.1 463.4 340.5 463.1 341.8 455.2 283.8 391.1 285 390.1 343.5 454.8Z" class="brainPath"/>
<path d="M374.1 496.1 373.4 477 364.6 477 364.6 475.4 374.9 475.4 375.6 496.1Z" class="brainPath"/>
<path d="M401.5 494.1 400.8 471.8 390.7 460.3 391.9 459.3 402.3 471.1 402.4 471.4 403 494Z" class="brainPath"/>
<path d="M435.8 427.7 381.3 427.7 372.2 417.2 373.4 416.1 382.1 426.1 435.8 426.1Z" class="brainPath"/>
<path d="M111.5 152.2 110.6 150.9 138.9 131.5 139.2 131.5 200.2 132.2 200.1 133.8 139.4 133.1Z" class="brainPath"/>
<path d="M137.2 238.7 111 219 111 185.1 112.6 185.1 112.6 218.3 135.6 235.6 135.6 147.6 144.4 142.5 197.4 142.5 197.4 144.1 144.9 144.1 137.2 148.5Z" class="brainPath"/>
<rect x="122.6" y="154.9" width="1.6" height="56.2" class="brainRect"/>
<path d="M185.2 253.6 183.6 253.6 183.6 168.7 146.6 168.7 146.6 152.8 200.2 152.8 200.2 154.4 148.2 154.4 148.2 167.2 185.2 167.2Z" class="brainPath"/>
<path d="M175.9 201.5 174.4 201.5 174.4 191.9 160.8 178.3 146.7 178.3 146.7 176.8 161.4 176.8 175.9 191.3Z" class="brainPath"/>
<path d="M166.7 200.4 165.1 200.4 165.1 196.3 158 188.6 150.8 188.6 150.8 187 158.7 187 166.7 195.7Z" class="brainPath"/>
<path d="M174.1 243.4 145.2 243.4 145.2 222.8 146.8 222.8 146.8 241.8 174.1 241.8Z" class="brainPath"/>
<path d="M165.9 234.5 153.5 234.5 153.5 222.8 155 222.8 155 232.9 165.9 232.9Z" class="brainPath"/>
<path d="M166.7 220.9 165.1 220.9 165.1 211.9 146 211.9 146 210.3 166.7 210.3Z" class="brainPath"/>
<rect x="173.3" y="220.7" width="1.6" height="13" class="brainRect"/>
<path d="M200.3 121.5 150.1 121.5 150.1 119.9 198.7 119.9 198.7 25.2 200.3 25.2Z" class="brainPath"/>
<path d="M184.5 109.1 168.6 109.1 168.6 107.6 182.9 107.6 182.9 20.3 184.5 20.3Z" class="brainPath"/>
<path d="M69 107.5 53.3 75.4 54.7 74.7 69.6 105.1 81 97.4 81.8 98.7Z" class="brainPath"/>
<path d="M127.3 80.2 126.4 79 135.6 71.7 135.6 28.2 137.2 28.2 137.2 72.4Z" class="brainPath"/>
<path d="M96.9 87.3 96 86 122 66.8 122.4 36.9 124 36.9 123.5 67.6Z" class="brainPath"/>
<path d="M76.8 88.2 75.9 86.9 104.8 66.8 104.1 40.1 110.7 36.4 111.5 37.8 105.7 41 106.4 67.6Z" class="brainPath"/>
<rect x="113" y="47.4" width="1.6" height="15.8" class="brainRect"/>
<path d="M68.1 83.7 61.1 69.7 62.5 68.9 68.7 81.4 96.3 62.5 97.1 63.8Z" class="brainPath"/>
<path d="M71 69.8 67.9 62.8 69.3 62.1 71.7 67.4 94.5 52.4 94.5 44.6 96.1 44.6 96.1 53.3Z" class="brainPath"/>
<path d="M94.1 288.5 92.5 288.5 92.5 257.8 39.2 257.8 39.2 256.2 94.1 256.2Z" class="brainPath"/>
<path d="M95.3 302.3 80.8 302.3 80.8 270.1 39.2 270.1 39.2 268.6 82.4 268.6 82.4 300.8 95.3 300.8Z" class="brainPath"/>
<path d="M70.1 298.8 68.5 298.8 68.5 281.1 48.7 281.1 48.7 279.5 70.1 279.5Z" class="brainPath"/>
<path d="M135.2 315.9 133.6 315.9 133.6 283.5 112.7 270.7 113.6 269.4 135.2 282.6Z" class="brainPath"/>
<path d="M126.2 303 113.7 303 113.7 284.4 115.3 284.4 115.3 301.4 126.2 301.4Z" class="brainPath"/>
<path d="M253.6 318.1 253.6 316.5 295.9 315.8 294.6 212.1 294.9 211.9 382.3 137.4 381.6 26.1 383.2 26.1 383.9 138.2 383.6 138.4 296.2 212.8 297.5 317.4Z" class="brainPath"/>
<path d="M507.2 241.4 454.3 241.4 454.3 239.8 505.6 239.8 505.6 114.2 458.5 65.7 459.7 64.6 507.2 113.5Z" class="brainPath"/>
<path d="M360 101.2 308.3 58.7 308.3 6.3 309.9 6.3 309.9 58 361 100Z" class="brainPath"/>
<path d="M364.1 124 293.9 66.9 293.9 4.2 295.5 4.2 295.5 66.2 365.1 122.8Z" class="brainPath"/>
<path d="M493.4 198.2 458.1 198.2 457.8 197.9 401.5 136.7 401.5 68.6 403 68.6 403 136.1 458.8 196.6 493.4 196.6Z" class="brainPath"/>
<path d="M496.1 215.3 452.6 215.3 452.4 215.1 401.7 161.6 402.8 160.6 453.3 213.7 496.1 213.7Z" class="brainPath"/>
<path d="M283.7 307.1 235.9 307.1 225.4 275.6 225.4 111.5 213.1 103.3 213.1 11.1 214.6 11.1 214.6 102.4 227 110.7 227 275.4 237 305.5 283.7 305.5Z" class="brainPath"/>
<path d="M238.6 118.6 237 118.6 237 102.6 226.1 95.1 226.1 12.4 227.7 12.4 227.7 94.2 238.6 101.8Z" class="brainPath"/>
<path d="M278.5 199 237.7 173.2 237.7 151.5 239.3 151.5 239.3 172.3 279.4 197.7Z" class="brainPath"/>
<path d="M283.2 274.8 281.6 274.8 281.6 215.7 236.7 187.8 237.6 186.5 283.2 214.8Z" class="brainPath"/>
<path d="M273.5 297.5 245.5 297.5 237.7 273.6 237.7 200.8 239.3 200.8 239.3 273.3 246.6 296 273.5 296Z" class="brainPath"/>
<path d="M270.1 270 268.6 270 268.6 223.2 248.8 211.1 249.7 209.8 270.1 222.3Z" class="brainPath"/>
<path d="M264.4 285.2 252.3 285.2 248.7 270 248.7 223.4 250.3 223.4 250.3 269.9 253.5 283.6 264.4 283.6Z" class="brainPath"/>
<rect x="258.3" y="237.8" width="1.6" height="32.2" class="brainRect"/>
<path d="M239.1 85.6 237.9 84.5 280.9 34.1 280.9 4.2 282.5 4.2 282.5 34.7Z" class="brainPath"/>
<path d="M240.5 63 239.3 61.9 269.9 27.9 269.9 12.4 271.5 12.4 271.5 28.5Z" class="brainPath"/>
<path d="M239.1 48.1 239.1 20 240.7 20 240.7 43.9 259.6 22.4 259.6 7.9 261.2 7.9 261.2 23Z" class="brainPath"/>
<rect x="249.4" y="4.2" width="1.6" height="14.4" class="brainRect"/>
<path d="M338.6 150.5 338 150.1 256.4 84.1 243.9 97.2 242.7 96.2 256.3 81.9 338.5 148.4 351.7 136.5 352.8 137.7Z" class="brainPath"/>
<rect x="259.9" y="64.4" width="27.2" height="1.6" class="brainRect" transform="matrix(0.6549 -0.7557 0.7557 0.6549 45.1116 229.16)"/>
<rect x="305.2" y="65.7" width="1.6" height="81.2" class="brainRect" transform="matrix(0.641 -0.7675 0.7675 0.641 28.2647 273.019)"/>
<rect x="315.3" y="73.4" width="1.6" height="53" class="brainRect" transform="matrix(0.6372 -0.7707 0.7707 0.6372 37.6642 279.812)"/>
<rect x="288" y="173.2" width="71.9" height="1.6" class="brainRect" transform="matrix(0.7576 -0.6527 0.6527 0.7576 -35.0028 253.643)"/>
<path d="M372.9 126.3 371.3 126.3 371.3 26.5 365.2 20.5 366.3 19.4 372.9 25.8Z" class="brainPath"/>
<path d="M331.1 41.4 329.5 41.4 329.5 34.1 337.1 30.6 337.1 17.2 338.7 17.2 338.7 31.6 331.1 35.2Z" class="brainPath"/>
<path d="M357.1 82.3 355.6 82.3 356.2 34 346.2 24 347.3 22.8 357.8 33.4Z" class="brainPath"/>
<rect x="320.6" y="8.3" width="1.6" height="41.1" class="brainRect"/>
<path d="M344.8 68.7 332.2 60.1 333 58.8 343.2 65.8 343.2 40.5 344.8 40.5Z" class="brainPath"/>
<path d="M309.2 311.1 307.6 311.1 307.6 216.2 381.2 153.6 382.2 154.9 309.2 217Z" class="brainPath"/>
<path d="M281.1 186.3 250.1 166.3 250.1 112.7 251.6 112.7 251.6 165.5 282 185Z" class="brainPath"/>
<path d="M295.5 173.4 293.9 173.4 293.9 145.4 266.5 145.4 266.5 117.9 268.1 117.9 268.1 143.9 295.5 143.9Z" class="brainPath"/>
<path d="M327.1 154.9 300.2 132.9 277.5 132.4 277.5 118.6 279 118.6 279 130.9 300.8 131.3 328.1 153.6Z" class="brainPath"/>
<rect x="304.3" y="164.5" width="18.8" height="1.6" class="brainRect" transform="matrix(0.7454 -0.6666 0.6666 0.7454 -30.3415 251.205)"/>
<rect x="299.8" y="156" width="8.9" height="1.6" class="brainRect" transform="matrix(0.7566 -0.6538 0.6538 0.7566 -28.4967 237.075)"/>
<rect x="283.6" y="156.5" width="1.6" height="19" class="brainRect"/>
<rect x="273.3" y="152.9" width="1.6" height="11.6" class="brainRect"/>
<rect x="258.3" y="100.8" width="1.6" height="46.8" class="brainRect"/>
<path d="M393.5 120 391.9 120 391.9 53.8 410.7 39.9 411.6 41.2 393.5 54.6Z" class="brainPath"/>
<path d="M408.3 29.8C408.3 31.5 407 32.9 405.3 32.9 403.6 32.9 402.2 31.6 402.2 29.9 402.2 28.2 403.5 26.8 405.2 26.8 406.9 26.7 408.3 28.1 408.3 29.8Z" class="brainPath"/>
<rect x="392.8" y="33.6" width="14.3" height="1.6" class="brainRect" transform="matrix(0.8056 -0.5924 0.5924 0.8056 57.3618 243.594)"/>
<path d="M414 111.8 412.4 111.8 412.4 59.3 429.9 46.7 430.8 48 414 60.1Z" class="brainPath"/>
<rect x="394.1" y="438" width="1.6" height="15.1" class="brainRect" transform="matrix(0.7918 -0.6108 0.6108 0.7918 -189.941 333.974)"/>
<path d="M488.9 183.1 463.6 183.1 463.3 182.9 416.1 132.2 417.2 131.1 464.3 181.5 488.9 181.5Z" class="brainPath"/>
<path d="M458.4 398.2 394.3 398.2 394.1 398 322.7 312.8 322.7 222.1 324.3 222.1 324.3 312.2 395.1 396.7 458.4 396.7Z" class="brainPath"/>
<rect x="337.2" y="271.3" width="154.1" height="1.6" class="brainRect"/>
<rect x="337.2" y="283.6" width="154.1" height="1.6" class="brainRect"/>
<path d="M393.9 377.9 341.4 314.4 342.6 313.4 394.2 375.9 460.1 336.5 460.9 337.8Z" class="brainPath"/>
<path d="M496.9 315.9 495.3 315.9 495.3 299.6 337.2 299.6 337.2 298 496.9 298Z" class="brainPath"/>
<path d="M407.9 385.9 397.5 385.9 397.5 384.3 407.5 384.3 461.5 353.6 462.2 355Z" class="brainPath"/>
<path d="M445.4 257.8 335.7 257.8 335.7 216.2 336 216 390.4 169.1 391.5 170.3 337.3 217 337.3 256.2 445.4 256.2Z" class="brainPath"/>
<rect x="444.5" y="218.6" width="1.6" height="30.2" class="brainRect" transform="matrix(0.9997 -0.022735 0.022735 0.9997 -5.1985 10.1842)"/>
<rect x="434" y="209.1" width="1.6" height="34.3" class="brainRect" transform="matrix(0.9998 -0.0199935 0.0199935 0.9998 -4.4353 8.7383)"/>
<rect x="424.4" y="198.1" width="1.6" height="50.7" class="brainRect" transform="matrix(0.9999 -0.0135085 0.0135085 0.9999 -2.9796 5.7642)"/>
<path d="M351 248.1 349.4 248.1 349.4 221 349.7 220.8 398.3 179.7 399.3 180.9 351 221.8Z" class="brainPath"/>
<path d="M366 240.6 364.5 240.6 364.5 224.4 364.7 224.2 407.2 188.6 408.2 189.8 366 225.2Z" class="brainPath"/>
<path d="M415.4 235.5 413.8 235.5 413.8 204.5 412.8 203.4 414 202.4 415.4 204Z" class="brainPath"/>
<path d="M405.8 246.2 377.6 246.2 377.6 244.6 404.2 244.6 404.2 211.5 399.7 207.6 400.7 206.4 405.8 210.7Z" class="brainPath"/>
<path d="M396.2 234.5 381.7 234.5 381.7 232.9 394.6 232.9 394.6 228.5 396.2 228.5Z" class="brainPath"/>
<path d="M214.5 375 186.6 374.3 176.8 347.7 178.3 347.2 187.7 372.7 214.6 373.4Z" class="brainPath"/>
<rect x="468.6" y="101.5" width="1.6" height="68.5" class="brainRect"/>
<rect x="469.4" y="133.6" width="26.7" height="1.6" class="brainRect"/>
<rect x="469.4" y="145.2" width="23.8" height="1.6" class="brainRect"/>
<rect x="469.4" y="156.9" width="26.7" height="1.6" class="brainRect"/>
<rect x="469.4" y="168.5" width="26.7" height="1.6" class="brainRect"/>
<path d="M455.1 154.9 453.5 154.9 454.2 83.3 435.9 60.2 437.1 59.2 455.8 82.7Z" class="brainPath"/>
<path d="M440.7 135.8 439.1 135.8 439.1 87.4 427.7 73.6 428.9 72.6 440.7 86.8Z" class="brainPath"/>
<rect x="426.1" y="99.8" width="1.6" height="26.4" class="brainRect"/>
<path d="M494.1 119.4 481.6 119.4 481.6 108 483.2 108 483.2 117.8 494.1 117.8Z" class="brainPath"/>
<path d="M462 387.3 429 387.3 429 385.7 460.4 385.7 460.4 370.1 462 370.1Z" class="brainPath"/>
<rect x="454.3" y="226.8" width="36.3" height="1.6" class="brainRect"/>
<path d="M516.3 351 490.8 351 490.8 349.4 515.6 349.4 528.7 336.4 557.7 335.7 557.8 337.3 529.3 338Z" class="brainPath"/>
<path d="M519.7 361.9 495.8 361.9 495.8 360.4 519.1 360.4 531.4 347.3 549.5 347.3 549.5 348.9 532.1 348.9Z" class="brainPath"/>
<rect x="483" y="368.7" width="1.6" height="31.5" class="brainRect"/>
<rect x="493.3" y="379" width="1.6" height="12.3" class="brainRect"/>
<rect x="529" y="297.3" width="42.5" height="1.6" class="brainRect"/>
<rect x="529" y="311.7" width="39" height="1.6" class="brainRect"/>
<path d="M572.2 261.9 530.1 261.9 516.6 249.8 516.6 135.3 518.1 135.3 518.1 249.1 530.7 260.3 572.2 260.3Z" class="brainPath"/>
<path d="M572.2 287.3 527.3 287.3 518.2 278.1 519.3 277 527.9 285.7 572.2 285.7Z" class="brainPath"/>
<path d="M563.9 324.9 527.3 324.9 518.2 315.8 519.3 314.7 527.9 323.4 563.9 323.4Z" class="brainPath"/>
<rect x="530.4" y="276.1" width="41.8" height="1.6" class="brainRect"/>
<rect x="530.4" y="268.6" width="41.8" height="1.6" class="brainRect"/>
<path d="M572.2 252.3 534.9 252.3 526.8 245 526.8 244.7 526.1 139.9 527.7 139.9 528.4 244.3 535.5 250.7 572.2 250.7Z" class="brainPath"/>
<path d="M534.7 196.6C532.5 196.6 530.8 194.8 530.8 192.7 530.8 190.6 532.6 188.8 534.7 188.8 536.9 188.8 538.6 190.6 538.6 192.7 538.6 194.8 536.9 196.6 534.7 196.6ZM534.7 190.3C533.4 190.3 532.3 191.4 532.3 192.7 532.3 194 533.4 195.1 534.7 195.1 536 195.1 537.1 194 537.1 192.7 537.1 191.4 536 190.3 534.7 190.3Z" class="brainPath"/>
<path d="M556.6 200.3 546.5 200.3 539.6 193.4 537.7 193.4 537.7 191.8 540.3 191.8 547.1 198.7 556.6 198.7Z" class="brainPath"/>
<path d="M534.7 208C532.5 208 530.8 206.2 530.8 204.1 530.8 202 532.6 200.2 534.7 200.2 536.9 200.2 538.6 202 538.6 204.1 538.6 206.2 536.9 208 534.7 208ZM534.7 201.7C533.4 201.7 532.3 202.8 532.3 204.1 532.3 205.4 533.4 206.5 534.7 206.5 536 206.5 537.1 205.4 537.1 204.1 537.1 202.8 536 201.7 534.7 201.7Z" class="brainPath"/>
<path d="M556.6 211.7 546.5 211.7 539.6 204.8 537.7 204.8 537.7 203.2 540.3 203.2 547.1 210.1 556.6 210.1Z" class="brainPath"/>
<path d="M534.7 219.4C532.5 219.4 530.8 217.6 530.8 215.5 530.8 213.3 532.6 211.6 534.7 211.6 536.9 211.6 538.6 213.4 538.6 215.5 538.6 217.7 536.9 219.4 534.7 219.4ZM534.7 213.1C533.4 213.1 532.3 214.2 532.3 215.5 532.3 216.8 533.4 217.9 534.7 217.9 536 217.9 537.1 216.8 537.1 215.5 537.1 214.2 536 213.1 534.7 213.1Z" class="brainPath"/>
<path d="M561.1 222C561.1 223.7 559.7 225.1 558 225.1 556.3 225.1 554.9 223.7 554.9 222 554.9 220.3 556.3 218.9 558 218.9 559.7 218.9 561.1 220.3 561.1 222Z" class="brainPath"/>
<path d="M556.6 223.1 546.5 223.1 539.6 216.2 537.7 216.2 537.7 214.7 540.3 214.7 547.1 221.5 556.6 221.5Z" class="brainPath"/>
<path d="M534.7 230.9C532.5 230.9 530.8 229.1 530.8 227 530.8 224.8 532.6 223.1 534.7 223.1 536.9 223.1 538.6 224.9 538.6 227 538.6 229.1 536.9 230.9 534.7 230.9ZM534.7 224.6C533.4 224.6 532.3 225.7 532.3 227 532.3 228.3 533.4 229.4 534.7 229.4 536 229.4 537.1 228.3 537.1 227 537.1 225.6 536 224.6 534.7 224.6Z" class="brainPath"/>
<path d="M561.1 233.4C561.1 235.1 559.7 236.5 558 236.5 556.3 236.5 554.9 235.1 554.9 233.4 554.9 231.7 556.3 230.3 558 230.3 559.7 230.3 561.1 231.7 561.1 233.4Z" class="brainPath"/>
<path d="M556.6 234.5 546.5 234.5 539.6 227.7 537.7 227.7 537.7 226.1 540.3 226.1 547.1 232.9 556.6 232.9Z" class="brainPath"/>
<rect x="538.6" y="242.5" width="32.2" height="1.6" class="brainRect"/>
<path d="M551.6 185.9 534.4 185.9 534.4 154.9 535.9 154.9 535.9 184.3 551.6 184.3Z" class="brainPath"/>
<rect x="541.2" y="163.8" width="1.6" height="14.4" class="brainRect"/>
<path d="M424.3 343.5 372.3 329.6 357.1 310.3 358.3 309.3 373.3 328.3 424 341.8 443.6 329 444.5 330.3Z" class="brainPath"/>
<rect x="392.9" y="324.7" width="1.6" height="40.8" class="brainRect" transform="matrix(0.2858 -0.9583 0.9583 0.2858 -49.4847 623.695)"/>
<path d="M395.2 364.9 391.1 360.1 392.4 359 395.6 362.8 406.6 355.7 407.5 357Z" class="brainPath"/>
<rect x="379" y="311.7" width="96.1" height="1.6" class="brainRect"/>
<rect x="408.1" y="309.7" width="1.6" height="29.8" class="brainRect" transform="matrix(0.1991 -0.98 0.98 0.1991 9.3687 660.679)"/>
<path d="M45.3 94.3C45.3 96 43.9 97.4 42.2 97.4 40.5 97.4 39.1 96 39.1 94.3 39.1 92.6 40.5 91.2 42.2 91.2 43.9 91.2 45.3 92.6 45.3 94.3Z" class="brainPath"/>
<path d="M99.9 63C99.9 64.7 98.5 66.1 96.8 66.1 95.1 66.1 93.7 64.7 93.7 63 93.7 61.3 95.1 59.9 96.8 59.9 98.5 59.9 99.9 61.3 99.9 63Z" class="brainPath"/>
<path d="M203.3 133.1C203.3 134.8 201.9 136.2 200.2 136.2 198.5 136.2 197.1 134.8 197.1 133.1 197.1 131.4 198.5 130 200.2 130 202 130 203.3 131.4 203.3 133.1Z" class="brainPath"/>
<path d="M117 45.7C117 47.4 115.6 48.8 113.9 48.8 112.2 48.8 110.8 47.4 110.8 45.7 110.8 44 112.2 42.6 113.9 42.6 115.6 42.6 117 44 117 45.7Z" class="brainPath"/>
<ellipse cx="18.6" cy="169.9" rx="6.7" ry="6.7" class="brainEllipse" transform="matrix(0.9999 -0.0131183 0.0131183 0.9999 -2.2271 0.2587)"/>
<ellipse cx="199.2" cy="18.4" rx="7.3" ry="7.3" class="brainEllipse" transform="matrix(0.229 -0.9734 0.9734 0.229 135.686 208.136)"/>
<ellipse cx="240.3" cy="61.9" rx="7.3" ry="7.3" class="brainEllipse" transform="matrix(0.2458 -0.9693 0.9693 0.2458 121.258 279.571)"/>
<ellipse cx="440" cy="132.4" rx="7.3" ry="7.3" class="brainEllipse" transform="matrix(0.9232 -0.3844 0.3844 0.9232 -17.0999 179.3)"/>
<ellipse cx="494.2" cy="213.5" rx="7.3" ry="7.3" class="brainEllipse" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -6.2094 412.001)"/>
<ellipse cx="529" cy="298.8" rx="7.3" ry="7.3" class="brainEllipse" transform="matrix(0.229 -0.9734 0.9734 0.229 116.93 745.328)"/>
<ellipse cx="384" cy="451.1" rx="7.3" ry="7.3" class="brainEllipse" transform="matrix(0.229 -0.9734 0.9734 0.229 -143.036 721.615)"/>
<ellipse cx="374.8" cy="498.7" rx="7.3" ry="7.3" class="brainEllipse" transform="matrix(0.3778 -0.9259 0.9259 0.3778 -228.566 657.308)"/>
<ellipse cx="554.3" cy="184.6" rx="7.3" ry="7.3" class="brainEllipse" transform="matrix(0.229 -0.9734 0.9734 0.229 247.629 681.874)"/>
<ellipse cx="339.2" cy="117.6" rx="7.3" ry="7.3" class="brainEllipse" transform="matrix(0.2305 -0.9731 0.9731 0.2305 146.581 420.521)"/>
<circle cx="184.9" cy="252.7" r="3.1" class="brainCircle"/>
<circle cx="126.5" cy="80.1" r="3.1" class="brainCircle"/>
<circle cx="136.6" cy="27.4" r="3.1" class="brainCircle"/>
<circle cx="213.7" cy="10.8" r="3.1" class="brainCircle"/>
<circle cx="264.7" cy="75.2" r="3.1" class="brainCircle"/>
<circle cx="337.3" cy="132.1" r="3.1" class="brainCircle"/>
<circle cx="301.7" cy="159.6" r="3.1" class="brainCircle"/>
<circle cx="363.5" cy="122.7" r="3.1" class="brainCircle"/>
<circle cx="435.8" cy="58" r="3.1" class="brainCircle"/>
<circle cx="454.3" cy="155.3" r="3.1" class="brainCircle"/>
<circle cx="526.9" cy="140.2" r="3.1" class="brainCircle"/>
<circle cx="535.4" cy="154.4" r="3.1" class="brainCircle"/>
<circle cx="496.3" cy="134.3" r="3.1" class="brainCircle"/>
<circle cx="455.6" cy="240.9" r="3.1" class="brainCircle"/>
<circle cx="494.1" cy="118.6" r="3.1" class="brainCircle"/>
<circle cx="426.9" cy="124.1" r="3.1" class="brainCircle"/>
<circle cx="338.1" cy="284.1" r="3.1" class="brainCircle"/>
<circle cx="338.1" cy="298.5" r="3.1" class="brainCircle"/>
<circle cx="382.8" cy="233.6" r="3.1" class="brainCircle"/>
<circle cx="400.7" cy="206.7" r="3.1" class="brainCircle"/>
<circle cx="424.9" cy="198.7" r="3.1" class="brainCircle"/>
<circle cx="445.2" cy="248.4" r="3.1" class="brainCircle"/>
<circle cx="445.4" cy="256.9" r="3.1" class="brainCircle"/>
<circle cx="538.8" cy="243.1" r="3.1" class="brainCircle"/>
<circle cx="572.3" cy="251.5" r="3.1" class="brainCircle"/>
<circle cx="572.3" cy="260.2" r="3.1" class="brainCircle"/>
<circle cx="572.3" cy="276.7" r="3.1" class="brainCircle"/>
<circle cx="529.7" cy="313.2" r="3.1" class="brainCircle"/>
<circle cx="495.3" cy="360.6" r="3.1" class="brainCircle"/>
<circle cx="494.2" cy="390.6" r="3.1" class="brainCircle"/>
<circle cx="474.2" cy="404.5" r="3.1" class="brainCircle"/>
<circle cx="461.6" cy="336.4" r="3.1" class="brainCircle"/>
<circle cx="464" cy="412.8" r="3.1" class="brainCircle"/>
<circle cx="461.6" cy="371" r="3.1" class="brainCircle"/>
<circle cx="284.5" cy="375.8" r="3.1" class="brainCircle"/>
<circle cx="399.1" cy="385.1" r="3.1" class="brainCircle"/>
<circle cx="414.1" cy="350.8" r="3.1" class="brainCircle"/>
<circle cx="405.7" cy="357.4" r="3.1" class="brainCircle"/>
<circle cx="341.7" cy="313.5" r="3.1" class="brainCircle"/>
<circle cx="356.7" cy="80.9" r="3.1" class="brainCircle"/>
<circle cx="250.8" cy="111.9" r="3.1" class="brainCircle"/>
<circle cx="238.9" cy="84.6" r="3.1" class="brainCircle"/>
<circle cx="250" cy="4.4" r="3.1" class="brainCircle"/>
<circle cx="281.8" cy="4.9" r="3.1" class="brainCircle"/>
<circle cx="321.5" cy="8.6" r="3.1" class="brainCircle"/>
<circle cx="321.5" cy="49" r="3.1" class="brainCircle"/>
<circle cx="330" cy="43" r="3.1" class="brainCircle"/>
<circle cx="344.1" cy="40.5" r="3.1" class="brainCircle"/>
<circle cx="365.5" cy="19.6" r="3.1" class="brainCircle"/>
<circle cx="294.6" cy="5.1" r="3.1" class="brainCircle"/>
<circle cx="68.3" cy="62.1" r="3.1" class="brainCircle"/>
<circle cx="53.9" cy="293.7" r="3.1" class="brainCircle"/>
<circle cx="39.2" cy="257" r="3.1" class="brainCircle"/>
<circle cx="145.9" cy="211.2" r="3.1" class="brainCircle"/>
<circle cx="154.4" cy="220.4" r="3.1" class="brainCircle"/>
<circle cx="49.4" cy="217.5" r="3.1" class="brainCircle"/>
<circle cx="126.5" cy="302.3" r="3.1" class="brainCircle"/>
<circle cx="176.9" cy="299.6" r="3.1" class="brainCircle"/>
<circle cx="176.9" cy="311.9" r="3.1" class="brainCircle"/>
<circle cx="177.4" cy="346.2" r="3.1" class="brainCircle"/>
<circle cx="215.6" cy="374.3" r="3.1" class="brainCircle"/>
<circle cx="249.6" cy="391.9" r="3.1" class="brainCircle"/>
<circle cx="469.8" cy="257.6" r="7.3" class="brainCircle"/>
<circle cx="496.3" cy="319.2" r="7.3" class="brainCircle"/>
<circle cx="469.8" cy="100.6" r="7.3" class="brainCircle"/>
<circle cx="259" cy="151.3" r="5.3" class="brainCircle"/>
<circle cx="309.3" cy="8.4" r="7.3" class="brainCircle"/>
<circle cx="558" cy="199.1" r="3.1" class="brainCircle"/>
<circle cx="558" cy="210.5" r="3.1" class="brainCircle"/>
<circle cx="10" cy="253.9" r="3.1" class="brainCircle"/>
<circle cx="18.9" cy="245.7" r="3.1" class="brainCircle"/>
<circle cx="44.5" cy="110.5" r="3.1" class="brainCircle"/>
<circle cx="53.9" cy="74.7" r="3.1" class="brainCircle"/>
<circle cx="112.6" cy="36.5" r="3.1" class="brainCircle"/>
<circle cx="113.7" cy="62.3" r="3.1" class="brainCircle"/>
<circle cx="114.6" cy="90" r="3.1" class="brainCircle"/>
<circle cx="149.2" cy="120.1" r="3.1" class="brainCircle"/>
<circle cx="123.5" cy="156.8" r="3.1" class="brainCircle"/>
<circle cx="214" cy="129" r="3.1" class="brainCircle"/>
</g>
</g>
</svg>`; 