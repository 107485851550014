import React from 'react';
import './ContentTypeSelector.css';
import { useNavigate } from 'react-router-dom';

const ContentTypeSelector = ({ selectedType, onTypeChange }) => {
  const navigate = useNavigate();

  const handleTypeChange = (type) => {
    onTypeChange(type);
    navigate('/home');
  };

  return (
    <div className="content-type-selector">
      <button
        className={`content-type-selector__button ${
          selectedType === 'meetings' ? 'content-type-selector__button--active' : ''
        }`}
        onClick={() => handleTypeChange('meetings')}
      >
        Meetings
      </button>
      <button
        className={`content-type-selector__button ${
          selectedType === 'documents' ? 'content-type-selector__button--active' : ''
        }`}
        onClick={() => handleTypeChange('documents')}
      >
        Documents
      </button>
    </div>
  );
};

export default ContentTypeSelector; 