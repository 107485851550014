import React from 'react';
import { brainSvg } from './brainSvg';

const BrainAnimation = () => {
  function addClass(query, theClass) {
    var x = document.querySelectorAll(query);
    for (var i = 0; i < x.length; i++) {
      x[i].classList.add(theClass);
    }
  }

  const colors = [
    {
      path: "rgb(0, 245, 255)", // Cyan blue
      circle: "rgb(158, 123, 255)", // Purple blue
      ellipse: "rgb(0, 191, 255)" // Deep sky blue
    },
    {
      path: "rgb(100, 255, 218)", // Teal
      circle: "rgb(0, 191, 255)", // Deep sky blue
      ellipse: "rgb(158, 123, 255)" // Purple blue
    },
    {
      path: "rgb(158, 123, 255)", // Purple blue
      circle: "rgb(0, 245, 255)", // Cyan blue
      ellipse: "rgb(100, 255, 218)" // Teal
    }
  ];

  function setColors(colorSet) {
    var bp = document.getElementsByClassName("brainPath");
    for (var i=0; i<bp.length; i++){
      bp[i].style.stroke = colorSet.path;
    }
    var br = document.getElementsByClassName("brainRect");
    for (var i=0; i<br.length; i++){
      br[i].style.stroke = colorSet.path;
      br[i].style.fill = colorSet.path;
    }
    var bc = document.getElementsByClassName("brainCircle");
    for (var i=0; i<bc.length; i++){
      bc[i].style.stroke = colorSet.circle;
      bc[i].style.fill = colorSet.circle;
    }
    var be = document.getElementsByClassName("brainEllipse");
    for (var i=0; i<be.length; i++){
      be[i].style.stroke = colorSet.ellipse;
      be[i].style.fill = colorSet.ellipse;
    }
  }

  function neuralize() {
    const brainContainer = document.querySelector(".brainContainer");
    if (brainContainer) {
      brainContainer.innerHTML = brainSvg;
    }
  }

  React.useEffect(() => {
    neuralize();
    // Set initial colors immediately
    setColors(colors[0]);
    let colorIndex = 0;
    
    const intervalId = setInterval(() => {
      colorIndex = (colorIndex + 1) % colors.length;
      setColors(colors[colorIndex]);
    }, 2000);

    return () => clearInterval(intervalId);
  }, []);

  return <div className="brainContainer" />;
};

export default BrainAnimation;
