const baseUrl = process.env.REACT_APP_DATA_BACKEND_BASE_URL;

export const fetchDocuments = async (token) => {
  try {
    const response = await fetch(`${baseUrl}/document-summaries/all`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch documents');
    }

    return await response.json();
  } catch (error) {
    console.error('Error in fetchDocuments:', error);
    throw error;
  }
};

export const fetchDocumentSummary = async (id, token) => {
  try {
    const response = await fetch(`${baseUrl}/document-summaries/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch document summary');
    }

    return await response.json();
  } catch (error) {
    console.error('Error in fetchDocumentSummary:', error);
    throw error;
  }
}; 