import React, { useState, useEffect } from 'react';
import './DocumentList.css';
import { useNavigate } from 'react-router-dom';
import { fetchDocuments } from '../../services/documents';
import { useAuth } from '../../hooks/useAuth';

function DocumentList({ contentType }) {
  const [documents, setDocuments] = useState([]);
  const navigate = useNavigate();
  const { authToken } = useAuth();
  const [expandedTopics, setExpandedTopics] = useState({});

  useEffect(() => {
    if (authToken && contentType === 'documents') {
      fetchDocuments(authToken)
        .then(response => {
          if (response?.summaries && Array.isArray(response.summaries)) {
            const sortedDocuments = groupAndSortDocuments(response.summaries);
            setDocuments(sortedDocuments);
          }
        })
        .catch(error => {
          console.error('Error fetching documents:', error);
        });
    }
  }, [authToken, contentType]);

  const formatDate = (timestamp) => {
    const date = new Date(Number(timestamp) * 1000);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const getGroupDate = (timestamp) => {
    const date = new Date(Number(timestamp) * 1000);
    return date.toISOString().split('T')[0];
  };

  const groupAndSortDocuments = (docs) => {
    const grouped = docs.reduce((acc, doc) => {
      const date = getGroupDate(doc.timestamp);
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(doc);
      return acc;
    }, {});

    return Object.entries(grouped)
      .map(([date, documents]) => ({
        date: formatDate(documents[0].timestamp),
        rawDate: date,
        documents: documents.sort((a, b) => 
          Number(b.timestamp) - Number(a.timestamp)
        )
      }))
      .sort((a, b) => new Date(b.rawDate) - new Date(a.rawDate));
  };

  const handleDocumentView = (id) => {
    navigate(`/document-detailed/${id}`);
  };

  const handleShortSummary = (id) => {
    navigate(`/document-short/${id}`);
  };

  const handleTopicsExpand = (documentIndex) => {
    setExpandedTopics(prev => ({
      ...prev,
      [documentIndex]: !prev[documentIndex]
    }));
  };

  if (contentType === 'meetings') {
    return null;
  }

  return (
    <div className="document-list">
      <div className="document-list__header">
        <h1 className="document-list__title">Your Documents</h1>
      </div>
      <div className="document-list__container">
        {documents.map((documentGroup, groupIndex) => (
          <div key={groupIndex} className="document-list__group">
            <h2 className="document-list__group-header">
              {documentGroup.date} · {documentGroup.documents.length} Documents
            </h2>
            {documentGroup.documents.map((document, documentIndex) => (
              <div key={documentIndex} className="document-list__card">
                <div className="document-list__card-header">
                  <div className="document-list__title-wrapper">
                    <h3 className="document-list__document-title">
                      Document
                    </h3>
                    <div className="document-list__document-preview">
                      {document.summary.summary}
                    </div>
                  </div>
                </div>
                {document.summary?.importants && (
                  <div className="document-list__content">
                    {document.summary.importants.map((important, index) => (
                      <div key={index} className="document-list__note">
                        <strong>{important.header}:</strong>
                        <div className="document-list__note-point">
                          {important.subitems[0]}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <div className="document-list__button-container">
                  <div className="document-list__buttons">
                    <button 
                      className="document-list__button document-list__button--summary"
                      onClick={() => handleShortSummary(document.meetingId)}
                    >
                      View Summary
                    </button>
                    <button 
                      className="document-list__button document-list__button--detailed"
                      onClick={() => handleDocumentView(document.meetingId)}
                    >
                      Full Summary
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
        {documents.length === 0 && (
          <div className="document-list__empty">
            <p>No documents found</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default DocumentList; 