import React, { useState, useEffect } from 'react';
import './MeetingList.css';
import { useNavigate } from 'react-router-dom';
import { fetchMeetings } from '../../services/meeting';
import { useAuth } from '../../hooks/useAuth';

function MeetingList({ contentType }) {
    const [meetings, setMeetings] = useState([]);
    const navigate = useNavigate();
    const { authToken } = useAuth();

    useEffect(() => {
        if (authToken) {
            fetchMeetings(authToken)
                .then(response => {
                    if (response?.summaries && Array.isArray(response.summaries)) {
                        const sortedMeetings = groupAndSortMeetings(response.summaries);
                        setMeetings(sortedMeetings);
                    }
                })
                .catch(error => {
                    console.error('Error fetching meetings:', error);
                });
        }
    }, [authToken]);

    const formatDate = (timestamp) => {
        const date = new Date(Number(timestamp) * 1000);
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    };

    const formatTime = (timestamp) => {
        const date = new Date(Number(timestamp) * 1000);
        return date.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        });
    };

    const getGroupDate = (timestamp) => {
        const date = new Date(Number(timestamp) * 1000);
        return date.toISOString().split('T')[0];
    };

    const groupAndSortMeetings = (meetings) => {
        const grouped = meetings.reduce((acc, meeting) => {
            const date = getGroupDate(meeting.timestamp);
            if (!acc[date]) {
                acc[date] = [];
            }
            acc[date].push(meeting);
            return acc;
        }, {});

        return Object.entries(grouped)
            .map(([date, summaries]) => ({
                date: formatDate(summaries[0].timestamp),
                rawDate: date,
                summaries: summaries.sort((a, b) => 
                    Number(b.timestamp) - Number(a.timestamp)
                )
            }))
            .sort((a, b) => new Date(b.rawDate) - new Date(a.rawDate));
    };

    const handleSummary = (id, type) => {
        navigate(`/${type}-summary/${id}`);
    };

    if (contentType === 'documents') {
        return null; // Or return your DocumentList component when it's created
    }

    return (
        <div className="meeting-list">
            <div className="meeting-list__header">
                <h1 className="meeting-list__title">Your Meetings</h1>
            </div>
            <div className="meeting-list__container">
                {meetings.map((meetingGroup, groupIndex) => (
                    <div key={groupIndex} className="meeting-list__group">
                        <h2 className="meeting-list__group-header">
                            {meetingGroup.date} · {meetingGroup.summaries.length} Meetings
                        </h2>
                        {meetingGroup.summaries.map((meeting, meetingIndex) => (
                            <div key={meetingIndex} className="meeting-list__card">
                                <div className="meeting-list__card-header">
                                    <div className="meeting-list__title-wrapper">
                                        <h3 className="meeting-list__meeting-title">
                                            Meeting
                                        </h3>
                                        <div className="meeting-list__meeting-date">
                                            {formatTime(meeting.timestamp)}
                                        </div>
                                    </div>
                                    <div className="meeting-list__buttons">
                                        <button 
                                            className="meeting-list__button meeting-list__button--summary"
                                            onClick={() => handleSummary(meeting.meetingId, 'brief')}
                                        >
                                            View Summary
                                        </button>
                                        <button 
                                            className="meeting-list__button meeting-list__button--detailed"
                                            onClick={() => handleSummary(meeting.meetingId, 'detailed')}
                                        >
                                            Full Details
                                        </button>
                                    </div>
                                </div>
                                <div className="meeting-list__content">
    {meeting.notes && meeting.notes.map((note, noteIndex) => (
        <div key={noteIndex} className="meeting-list__note">
            <strong>{note[0]}:</strong>
            <div className="meeting-list__note-point">
                {note[1]?.length > 0 && note[1][0]}
            </div>
        </div>
    ))}
</div>
                            </div>
                        ))}
                    </div>
                ))}
                {meetings.length === 0 && (
                    <div className="meeting-list__empty">
                        <p>No meetings found</p>
                    </div>
                )}
            </div>
        </div>
    );
}

export default MeetingList;