// SignUp.jsx
import React from 'react';
import { useForm } from 'react-hook-form';
import { Mail, Chrome } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import AnimatedBackground from '../LandingPage/AnimatedBackground';
import './SignUp.css';

const SignupComponent = ({ onSubmit, onGoogleSignup, onMicrosoftSignup, inviteToken }) => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const navigate = useNavigate();

  const handleGoogleButton = async () => {
    try {
      // Call the parent's onGoogleSignup, which handles the token
      await onGoogleSignup();
    } catch (error) {
      console.error('Google signup error:', error);
      alert('Failed to sign up with Google');
    }
  };

  const handleMicrosoftButton = async () => {
    try {
      // Call the parent's onMicrosoftSignup, which handles the token
      await onMicrosoftSignup();
    } catch (error) {
      console.error('Microsoft signup error:', error);
      alert('Failed to sign up with Microsoft');
    }
  };

  return (
    <div className="signup">
      <AnimatedBackground />
      <header className="header">
        <div className="logo-landing">Inveris</div>
      </header>

      <main className="signup__main">
        <div className="signup__container">
          <h1 className="signup__title">Create Account</h1>
          <p className="signup__subtitle">Sync your work calendar to start using our service</p>
          
          <div className="signup__oauth-buttons">
            <button 
              type="button"
              onClick={handleGoogleButton}
              className="signup__oauth-button signup__oauth-button--google"
            >
              <Chrome size={20} />
              Continue with Google
            </button>
            <button 
              type="button"
              className="signup__oauth-button signup__oauth-button--microsoft"
              onClick={handleMicrosoftButton}
            >
              <Mail size={20} />
              Continue with Microsoft
            </button>
          </div>

          <div className="signup__divider">
            <span className="signup__divider-text">OR</span>
          </div>

          <form className="signup__form" onSubmit={handleSubmit(onSubmit)}>
            <input
              type="email"
              placeholder="Email"
              className={`signup__input ${errors.email ? 'error' : ''}`}
              {...register("email", { 
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address"
                }
              })}
            />
            {errors.email && <p className="error-message">{errors.email.message}</p>}

            <input
              type="password"
              placeholder="Password"
              className={`signup__input ${errors.password ? 'error' : ''}`}
              {...register("password", { 
                required: "Password is required",
                minLength: {
                  value: 8,
                  message: "Password must be at least 8 characters"
                }
              })}
            />
            {errors.password && <p className="error-message">{errors.password.message}</p>}

            <input
              type="text"
              placeholder="Full Name"
              className={`signup__input ${errors.fullName ? 'error' : ''}`}
              {...register("fullName", { required: "Full Name is required" })}
            />
            {errors.fullName && <p className="error-message">{errors.fullName.message}</p>}

            <button type="submit" className="signup__submit">Create account</button>
          </form>


          <div className="signup__links">
            <a href="/terms" className="signup__link">Terms of Service</a>
            <span className="signup__link-separator">•</span>
            <a href="/privacy" className="signup__link">Privacy Policy</a>
          </div>

          <div className="signup__trial-note">
            Already have an account? 
            <a onClick={() => navigate('/login')} className="signup__login-link">
              Sign in
            </a>
          </div>
        </div>
      </main>
    </div>
  );
};

export default SignupComponent;
