// src/services/meeting.js

const baseUrl = process.env.REACT_APP_DATA_BACKEND_BASE_URL;

export const fetchMeetings = async (token) => {
    try {
        const response = await fetch(`${baseUrl}/block-summaries`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export const fetchBriefSummary = async (id, token) => {
    try {
        const response = await fetch(`${baseUrl}/block-summaries/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export const fetchDetailedSummary = async (id, token) => {
    try {
        const response = await fetch(`${baseUrl}/detailed-summaries/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export const fetchTeams = async (token) => {
    try {
        const response = await fetch(`${baseUrl}/teams`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (!response.ok) {
            throw new Error('Failed to fetch teams');
        }
        return await response.json();
    } catch (error) {
        console.error('Error during fetching teams:', error);
        throw error;
    }
};

export const addMeetingUrl = async (meetingUrl, teams, token) => {
    try {
        const response = await fetch(`${baseUrl}/deploy-recording-bot`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                meeting_url: meetingUrl,
                teams: teams
            })
        });
        
        if (!response.ok) {
            throw new Error('Failed to add meeting URL');
        }
        
        return await response.json();
    } catch (error) {
        console.error('Error adding meeting URL:', error);
        throw error;
    }
};

export const fetchMeetingOperations = async (token, status = null) => {
    try {
        let url = `${baseUrl}/meeting-operations/all`;
        if (status) {
            url += `?status=${status}`;
        }
        
        const response = await fetch(url, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        
        if (!response.ok) {
            throw new Error('Failed to fetch meeting operations');
        }
        
        return await response.json();
    } catch (error) {
        console.error('Error fetching meeting operations:', error);
        throw error;
    }
};