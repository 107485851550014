import React, { useState, useEffect } from 'react';
import {
  Search,
  Settings,
  UserPlus,
  ChevronRight,
  PlusCircle,
  MoreHorizontal,
} from 'lucide-react';
import { fetchTeams, removeUserFromTeam, addUserToTeam } from '../../services/team';
import CreateTeamModal from './CreateTeamModal';
import EditTeamModal from './EditTeamModal';
import AddUserToTeamModal from './AddUserToTeamModal';
import InviteCoworkerModal from './InviteCoworkerModal';  // Import the InviteCoworkerModal
import { useAuth } from '../../hooks/useAuth';
import './AdminTeamPage.css';

const AdminTeamManagement = () => {
  const [expandedTeams, setExpandedTeams] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [isCreateTeamModalOpen, setIsCreateTeamModalOpen] = useState(false);
  const [isEditTeamModalOpen, setIsEditTeamModalOpen] = useState(false);
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [isInviteCoworkerModalOpen, setIsInviteCoworkerModalOpen] = useState(false);  // Modal state for inviting coworkers
  const [teams, setTeams] = useState([]);
  const [teamToEdit, setTeamToEdit] = useState(null);
  const [teamToAddUser, setTeamToAddUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [menuOpen, setMenuOpen] = useState(null);
  const [userMenuOpen, setUserMenuOpen] = useState(null);

  const { authToken } = useAuth();

  useEffect(() => {
    const fetchTeamData = async () => {
      try {
        setLoading(true);
        const fetchedTeams = await fetchTeams(authToken);
        setTeams(fetchedTeams);
      } catch (err) {
        setError(err.message || 'Error fetching teams');
      } finally {
        setLoading(false);
      }
    };

    if (authToken) {
      fetchTeamData();
    }
  }, [authToken]);

  const toggleTeam = (teamName) => {
    setExpandedTeams((prev) => ({
      ...prev,
      [teamName]: !prev[teamName],
    }));
  };

  const filteredTeams = teams.filter((team) =>
    team.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    team.users.some((user) =>
      user.username.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.email.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const handleCreateTeam = (newTeam) => {
    setTeams([...teams, newTeam]);
  };

  const handleEditTeam = (updatedTeam) => {
    setTeams(
      teams.map((team) => (team.id === updatedTeam.id ? updatedTeam : team))
    );
  };

  const handleAddUser = (addedUser) => {
    // Update the relevant team with the new user
    setTeams(
      teams.map((team) =>
        team.id === addedUser.team_id
          ? { ...team, users: [...team.users, addedUser] }
          : team
      )
    );
  };

  const openEditModal = (team) => {
    setTeamToEdit(team);
    setIsEditTeamModalOpen(true);
    setMenuOpen(null);
  };

  const openAddUserModal = (team) => {
    setTeamToAddUser(team);
    setIsAddUserModalOpen(true);
    setMenuOpen(null);
  };

  const removeUser = async (userId, teamId) => {
    try {
      await removeUserFromTeam(teamId, userId, authToken);
      setTeams(
        teams.map((team) =>
          team.id === teamId
            ? {
                ...team,
                users: team.users.filter((user) => user.id !== userId),
              }
            : team
        )
      );
    } catch (error) {
      console.error('Failed to remove user:', error);
    } finally {
      setUserMenuOpen(null);
    }
  };

  const toggleMenu = (teamId) => {
    setMenuOpen(menuOpen === teamId ? null : teamId);
  };

  const toggleUserMenu = (userId) => {
    setUserMenuOpen(userMenuOpen === userId ? null : userId);
  };

  if (loading) {
    return <div className="admin-team-management__loading">Loading teams...</div>;
  }

  if (error) {
    return <div className="admin-team-management__error">Error: {error}</div>;
  }

  return (
    <div className="admin-team-management">
      <div className="admin-team-management__header">
        <div className="admin-team-management__header-left">
          <h2 className="admin-team-management__member-count">
            Teams · {teams.length}
          </h2>
          {/* Create Team button */}
          <button
            className="admin-team-management__create-team-btn"
            onClick={() => setIsCreateTeamModalOpen(true)}
          >
            <PlusCircle className="admin-team-management__icon" />
            Create Team
          </button>
        </div>

        <div className="admin-team-management__header-right">
          <div className="admin-team-management__search-container">
            <input
              type="text"
              placeholder="Search by team, name or email"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="admin-team-management__search-input"
            />
            <Search className="admin-team-management__search-icon" />
          </div>
          <button
            className="admin-team-management__invite-btn"
            onClick={() => setIsInviteCoworkerModalOpen(true)}  // Open the Invite Coworker modal
          >
            <UserPlus className="admin-team-management__icon" />
            Invite Coworkers
          </button>
          {/* <button className="admin-team-management__settings-btn">
            <Settings className="admin-team-management__icon" />
            Company Settings
          </button> */}
        </div>
      </div>

      <div className="admin-team-management__team-list">
        {filteredTeams.map((team) => (
          <div key={team.id} className="admin-team-management__team-item">
            <div className="admin-team-management__team-header">
              {/* Left side: chevron and team name */}
              <div className="admin-team-management__team-header-left">
                <ChevronRight
                  className={`admin-team-management__chevron ${
                    expandedTeams[team.name]
                      ? 'admin-team-management__chevron--rotated'
                      : ''
                  }`}
                  onClick={() => toggleTeam(team.name)}
                />
                <h3 className="admin-team-management__team-name">{team.name}</h3>
              </div>

              {/* Right side: three-dot menu */}
              <div className="admin-team-management__team-actions">
                <button
                  className="admin-team-management__menu-btn"
                  onClick={(event) => {
                    event.stopPropagation(); 
                    toggleMenu(team.id);
                  }}
                >
                  <MoreHorizontal />
                </button>
                {menuOpen === team.id && (
                  <div className="admin-team-management__menu">
                    <button
                      className="admin-team-management__menu-item"
                      onClick={(event) => {
                        event.stopPropagation();
                        openEditModal(team);
                      }}
                    >
                      Edit
                    </button>
                    <button
                      className="admin-team-management__menu-item"
                      onClick={(event) => {
                        event.stopPropagation();
                        openAddUserModal(team);
                      }}
                    >
                      Add User
                    </button>
                  </div>
                )}
              </div>
            </div>

            {expandedTeams[team.name] && (
              <div className="admin-team-management__member-list">
                {team.users.map((user) => (
                  <div key={user.id} className="admin-team-management__member-item">
                    <div className="admin-team-management__member-avatar">
                      {user.username.charAt(0).toUpperCase()}
                    </div>
                    <div className="admin-team-management__member-info">
                      <div className="admin-team-management__member-name">
                        {user.username}{' '}
                        <span className="admin-team-management__member-role">
                          ({user.is_admin ? 'Admin' : 'Member'})
                        </span>
                      </div>
                      <div className="admin-team-management__member-email">
                        {user.email}
                      </div>
                    </div>

                    {/* User actions (three-dot menu) */}
                    <div className="admin-team-management__member-actions">
                      <button
                        className="admin-team-management__menu-btn"
                        onClick={(event) => {
                          event.stopPropagation();
                          toggleUserMenu(user.id);
                        }}
                      >
                        <MoreHorizontal />
                      </button>
                      {userMenuOpen === user.id && (
                        <div className="admin-team-management__menu">
                          <button
                            className="admin-team-management__menu-item"
                            onClick={(event) => {
                              event.stopPropagation();
                              removeUser(user.id, team.id);
                            }}
                          >
                            Remove
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Create Team Modal */}
      {isCreateTeamModalOpen && (
        <CreateTeamModal
          onClose={() => setIsCreateTeamModalOpen(false)}
          onCreateTeam={handleCreateTeam}
        />
      )}

      {/* Edit Team Modal */}
      {isEditTeamModalOpen && teamToEdit && (
        <EditTeamModal
          onClose={() => setIsEditTeamModalOpen(false)}
          team={teamToEdit}
          onEditTeam={handleEditTeam}
        />
      )}

      {/* Add User Modal */}
      {isAddUserModalOpen && teamToAddUser && (
        <AddUserToTeamModal
          onClose={() => setIsAddUserModalOpen(false)}
          team={teamToAddUser}
          onAddUser={handleAddUser}
        />
      )}

      {/* Invite Coworker Modal */}
      {isInviteCoworkerModalOpen && (
        <InviteCoworkerModal onClose={() => setIsInviteCoworkerModalOpen(false)} />
      )}
    </div>
  );
};

export default AdminTeamManagement;
