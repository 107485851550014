import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MeetingList from '../components/MeetingList/MeetingList';
import BriefSummary from '../components/BriefSummary/BriefSummary';
import DetailedSummary from '../components/DetailedSummary/DetailedSummary';
import MeetingInsightsSearch from '../components/MeetingInsightsSearch/MeetingInsightsSearch'; 
import Upload from '../components/Upload/Upload';
import ConditionalTeamPage from '../components/ConditionalTeamPage/ConditionalTeamPage';
import AdminCompanyManagement from '../components/AdminCompanyManagement/AdminCompanyManagement';
import MeetingOperations from '../components/MeetingOperations/MeetingOperations';
import DocumentList from '../components/DocumentList/DocumentList';
import DocumentDetailedSummary from '../components/DocumentDetailedSummary/DocumentDetailedSummary';
import DocumentShortSummary from '../components/DocumentShortSummary/DocumentShortSummary';

const AppRoutes = ({ contentType }) => {
  return (
    <Routes>
      <Route path="home" element={
        <>
          <MeetingList contentType={contentType} />
          <DocumentList contentType={contentType} />
        </>
      } />
      <Route path="brief-summary/:id" element={<BriefSummary />} />
      <Route path="detailed-summary/:id" element={<DetailedSummary />} />
      <Route path="insights-search" element={<MeetingInsightsSearch />} /> 
      <Route path="uploads" element={<Upload />} />
      <Route path="team" element={<ConditionalTeamPage />} />
      <Route path="company" element={<AdminCompanyManagement />} />
      <Route path="operations" element={<MeetingOperations />} />
      <Route path="document-detailed/:id" element={<DocumentDetailedSummary />} />
      <Route path="document-short/:id" element={<DocumentShortSummary />} />
    </Routes>
  );
}

export default AppRoutes;