const baseUrl = process.env.REACT_APP_CORE_BACKEND_BASE_URL;

export const loginUser = async (email, password) => {
  const formData = new URLSearchParams();
  formData.append('username', email); 
  formData.append('password', password);

  try {
    const response = await fetch(`${baseUrl}/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formData.toString(),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.detail || 'Failed to login');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error during login:', error);
    throw error;
  }
};

export const loginWithGoogle = async (token = null) => {
  try {
    const params = new URLSearchParams();
    if (token) {
      params.append('invite_token', token);
    }
    const url = `${baseUrl}/auth/google/login?${params.toString()}`;
    window.location.href = url;
  } catch (error) {
    console.error('Error initiating Google login:', error);
    throw error;
  }
};

export const handleGoogleCallback = async (code) => {
  try {
    const response = await fetch(`${baseUrl}/auth/google/callback?code=${code}`);
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.detail || 'Google authentication failed');
    }
    return await response.json();
  } catch (error) {
    console.error('Error during Google callback:', error);
    throw error;
  }
};

export const loginWithMicrosoft = async (token = null) => {
  try {
    const params = new URLSearchParams();
    if (token) {
      params.append('invite_token', token); // Changed from 'state' to 'invite_token'
    }
    const url = `${baseUrl}/auth/microsoft/login?${params.toString()}`;
    window.location.href = url;
  } catch (error) {
    console.error('Error initiating Microsoft login:', error);
    throw error;
  }
};

export const handleOAuthCallback = async (code, provider) => {
  try {
    const response = await fetch(`${baseUrl}/auth/${provider}/callback?code=${code}`);
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.detail || `${provider} authentication failed`);
    }
    return await response.json();
  } catch (error) {
    console.error(`Error during ${provider} callback:`, error);
    throw error;
  }
};